import { IGlobalConfig } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	public currentYear: number = new Date().getFullYear();
	public hideFooter = false;
	public logoAltText = '';
	public footerImageLogo = '';

	public get configValues(): IGlobalConfig { return this.configService.configValues; }

	constructor(
			private readonly location: Location,
			public configService: ConfigService,
	) {
		if (document.URL?.includes('embedded'))
			sessionStorage.setItem('portalUrl', document.URL);

		if (sessionStorage.getItem('portalUrl') !== null)
			this.hideFooter = true;
	}

	public get footerAddress(): string {
		return this.configService.configValues.footerAddress.replace('\n', '<br>') ?? '';
	}

	public get email(): string {
		return this.configService?.configValues?.email ?? `sales@${this.configService.operatorPrettyName.toLowerCase()}.co.za`;
	}

	public get phone(): string {
		return this.configService.configValues?.supportNumber;
	}

	public get terms(): string {
		return this.configService?.configValues?.terms ?? 'All rights reserved. Integrated Network Management System © Automation Exchange';
	}

	public get imgLogo(): string {
		return this.configService?.configValues?.footerLogoImage;
	}

	public get imgAlt(): string {
		return this.configService?.configValues?.footerAltText??'Operator Logo';
	}
	public get footerImgClass(): string {
		return this.configService?.configValues?.footerlogoClass??'';
	}

	public get footerlogoText(): string {
		return this.configService?.configValues?.footerLogoText??'';
	}

	public goBack(): void {
		this.location.back();
	}

	public goForward(): void {
		this.location.forward();
	}
}
