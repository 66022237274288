import {
	AuthType,
	IDeviceStatus,
	INetworkState,
	IQrRedirect,
	ParamMetaData,
} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {PortalApi, StatusApi} from '../api';
import {map} from 'rxjs/operators';

@Injectable()
export class StatusService {

	constructor(
			private readonly http: HttpClient,
	) { }

	public getNetworkStatus(networkId: string): Observable<INetworkState> {
		return this.http.get<INetworkState>(StatusApi.networkStatus(networkId), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getDeviceFromSerial(serialNumber: string): Observable<IDeviceStatus> {
		return this.http.get<IDeviceStatus>(StatusApi.deviceFromSerial(serialNumber), {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}
	public getQrCodeRedirects(serialNumber: string): Observable<IQrRedirect> {
		return this.http.get<IQrRedirect>(PortalApi.qrRedirect(serialNumber), {
			params: new ParamMetaData({authToken: AuthType.NONE}),
		}).pipe(map(response => response));
	}
}

