import { AexNgToolboxModule } from '@aex/ngx-toolbox';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionHeaderComponent } from './accordion-header.component';
import { ExpandableCardComponent } from './expandable-card.component';
import { FieldsetComponent } from './fieldset.component';
import { TextBoxComponent } from './text-box.component';


@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		FontAwesomeModule,
		FormsModule,
		NgbCarouselModule,
		NgxUiLoaderModule,
		ReactiveFormsModule,
		RouterModule,
		AexNgToolboxModule.forChild(),
		BsDatepickerModule,
		TimepickerModule,
		DividerModule,
		DropdownModule,
        InputTextModule,
	],
	declarations: [
		AccordionHeaderComponent,
		ExpandableCardComponent,
		FieldsetComponent,
		TextBoxComponent,
	],
	exports: [
		AccordionHeaderComponent,
		ExpandableCardComponent,
		FieldsetComponent,
		TextBoxComponent,
	],
})
export class CommonLibUiComponentsModule {}
