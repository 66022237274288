import {
filterTruthy,
ICoordinates,
LoaderType,
NavigationService,
} from '@aex/ngx-toolbox';
import {
APP_ROUTES,
AuthType,
IArea,
IMapComponentInfo,
ParamMetaData, SERVER_CONFIG,
} from '@aex/shared/common-lib';
import {
AgmGeocoder,
LatLngLiteral,
MapsAPILoader,
} from '@agm/core';
import { GeocoderRequest, GeocoderResult } from '@agm/core/services/google-maps-types';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {
finalize,
first,
map,
switchMap,
take,
tap,
} from 'rxjs/operators';
import { TSMap } from 'typescript-map';
import { Router } from '@angular/router';
import { isNil } from 'lodash';
import { MapApi } from '../api';

@Injectable()
export class MapService {

	public lastPosition: LatLngLiteral;
	private location: GeolocationCoordinates;
	private readonly mapsLoadedSubject = new BehaviorSubject<boolean>(false);
	private readonly mapsLoadedStream = this.mapsLoadedSubject.asObservable().pipe(filterTruthy(), first());
	private readonly locationCache = new TSMap<string, GeocoderResult[]>();

	public get premiseName(): string {
		return `WebSignUp_${new Date().toISOString().replace(/[^0-9]/g, '')}`;
	}

	constructor(
			private readonly toast: ToastrService,
			private readonly http: HttpClient,
			private readonly injector: Injector,
			private readonly mapsAPILoader: MapsAPILoader,
			private readonly router: Router,
			private readonly navigationService: NavigationService,
	) {
		this.getLocation().subscribe();
	}

	private _geoCoder: AgmGeocoder;
	private get geoCoder(): Observable<AgmGeocoder> {
		return this.mapsLoaded().pipe(
				map(() => {
					if (!this._geoCoder)
						this._geoCoder = this.injector.get(AgmGeocoder);
					return this._geoCoder;
				}),
		);
	}

	private _startedLoadingMaps = false;

	public mapsLoaded(): Observable<boolean> {
		if (!this._startedLoadingMaps) {
			this._startedLoadingMaps = true;
			from(this.mapsAPILoader.load()).subscribe(() => this.mapsLoadedSubject.next(true));
		}
		return this.mapsLoadedStream;
	}

	private doGeoCode(request: GeocoderRequest): Observable<GeocoderResult> {
		const key = JSON.stringify(request);
		const cached = this.locationCache.get(key);

		const obs = cached
				? of(cached)
				: this.geoCoder.pipe(
						switchMap(geoCoder => geoCoder.geocode(request)),
						take(1),
						tap(result => this.locationCache.set(key, result)),
				);

		return obs.pipe(
				map(result => result.length && result[0]),
		);
	}

	public geoCodeByLocation(lat: LatLngLiteral | number, lng?: number): Observable<GeocoderResult> {
		const location: LatLngLiteral = typeof lat === 'number' ? {lat, lng} : lat;
		return this.doGeoCode({location});
	}

	public getAreaStatusInfo(latitude: number, longitude: number, loader: LoaderType = false): Observable<IArea> {
		return this.http.get<IArea>(MapApi.areas(latitude, longitude), {
			params: new ParamMetaData({authToken: AuthType.PROXIED, handleError: 'areas', loader}),
		});
	}

	public getCurrentPosition( completer?: (position: GeolocationPosition) => Observable<Partial<IMapComponentInfo>>,
	): Observable<Partial<IMapComponentInfo>> {
		return new Observable<Partial<IMapComponentInfo>>(observable => {
			navigator.geolocation.getCurrentPosition(
					(position: GeolocationPosition) => {

						const obs: Observable<Partial<IMapComponentInfo>> = completer
								? completer(position)
								: of({
									lat: position.coords.latitude,
									lng: position.coords.longitude,
								});

						obs.subscribe(result => {
							observable.next(result);
							observable.complete();
						});
					},
					// Failing to get the current position, no error, but can't return anything
					(error: GeolocationPositionError) => {
						const [type, message] = getPositionErrorMessage(error);
						if (type === 'info')
							this.toast.info(message);
						else
							this.toast.warning(message);

						observable.next({});
						observable.complete();
					},
			);
		});
	}

	public getLocation(loader?: string, force: boolean = true): Observable<GeolocationCoordinates> {
	if (force || !this.location) {
	this.navigationService.startLoading({loader});
	return new Observable<GeolocationCoordinates>(observer => {
		if (!(window.navigator && window.navigator.geolocation)) {
			observer.error('Unsupported Browser');
			this.toast.warning('This device or browser doesn\'t have access to your GPS, please enable it');
			this.router.navigateByUrl(APP_ROUTES.login.path).then();
		} else
			window.navigator.geolocation.getCurrentPosition(
				position => {
					this.location = position.coords;
					observer.next(this.location);
					observer.complete();
				},
				error => observer.error(error),
			);
	}).pipe(
		finalize(() => this.navigationService.stopLoading({loader})),
	);
} else
return of(this.location);
}

	// Calculate Distance Between 2 points
	public calculateDistance(source: ICoordinates, destination: ICoordinates): number {
		// Calc the difference
		const dLat = degreesToRadians(source.latitude - destination.latitude);
		const dLon = degreesToRadians(source.longitude - destination.longitude);
		// Get radians for latitudes
		const lat1 = degreesToRadians(source.latitude);
		const lat2 = degreesToRadians(destination.latitude);
		// Hardcore maths stuff
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
		const c = Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 2;
		const distance = EARTH_RADIUS * c;

		return Number(distance.toFixed(distance < 1 ? 1 : 2));
	}

	public isCloseEnough(destination: ICoordinates, radius: number, refreshLocation: boolean = false): Observable<boolean> {
		if (isNil(radius)) return of(true);
	const locationObs = this.location && !refreshLocation ? of(this.location) : this.getLocation();
	return locationObs.pipe(map(location => this.calculateDistance(location, destination) < radius));
	}

	// - Get Map Img Url based on Address
	public getMapUrl(address: string): string {
		const addressUrl = urlify(address);
		const params = new URLSearchParams({
			center: addressUrl,
			zoom: '15',
			size: '615x415',
			maptype: 'roadmap',
			markers: `color:red|${ addressUrl }`,
			key: SERVER_CONFIG.googleMapsKey,
		}).toString();
		return `https://maps.googleapis.com/maps/api/staticmap?${ params.toString() }`;
	}

}

// Earths radius in km
const EARTH_RADIUS = 6371;

function degreesToRadians(degrees: number): number {
	return (degrees * Math.PI) / 180;
}

function urlify(a: string): string {
	return a
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, '-')
		.replace(/^-+|-+$/g, '-')
		.replace(/^-+|-+$/g, '');
}
function getPositionErrorMessage(error: GeolocationPositionError): ['info' | 'warning', string] {
	switch (error.code) {
		case error.PERMISSION_DENIED:
			return ['info', 'You have disabled geolocation'];
		case error.POSITION_UNAVAILABLE:
			return ['warning', 'Device failure getting your location'];
		case error.TIMEOUT:
			return ['warning', 'Timeout getting your location'];
		default:
			return ['warning', error.message];
	}
}

