<div class="two-factor-init-page-container">
	<h3 class="admin-nexus-heading">
		Two-factor Authentication
	</h3>

	<hr/>

	<span class="two-factor-init-instructions">
  	Two-factor authentication is required to be added to your account. Please add another method of authentication.
	</span>

	<app-enable-two-factor-authentication-method [loginId]="loginId"  (onSuccessfulSave)="onSuccessfulSave()">
	</app-enable-two-factor-authentication-method>
</div>
