import { HttpErrorResponseExt, OnlineOfflineService, WithDestroy } from '@aex/ngx-toolbox';
import { AuthConfig, AuthType, getHTTPStatusText, getMetaData, HEADER_AUTH, IParamMetaData, Utils } from '@aex/shared/common-lib';
import {AuthService, BaseConfigService} from '@aex/shared/root-services';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ClientInterfaceAuthInterceptor extends WithDestroy() implements HttpInterceptor {

	private isOnline: boolean;

	constructor(
		private readonly authService: AuthService,
		private readonly configService: BaseConfigService,
		onlineService: OnlineOfflineService,
	) {
		super();
		this.noZombie(onlineService.onlineStatusStream).subscribe(x => this.isOnline = x);
	}

	private tokenFromAuthType(authType: AuthConfig): string {
		if (typeof authType === 'string')
			return authType;
		else
			switch (authType) {
				case AuthType.PROXIED:
				case AuthType.USER:
					return this.authService.authToken;
				default:
					return null;
			}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleUnauthorized(meta: IParamMetaData, error: HttpErrorResponse): Observable<HttpEvent<any>> {
		// If unauthorised, it depends on which token was used
		const authType = Utils.authTypeFromMeta(meta);
		switch (authType) {
			case AuthType.USER:
			case AuthType.PROXIED:
				this.authService.gotoLogin();
				return throwError(new HttpErrorResponseExt(error, error.toString(), true));
			default:
				return throwError(error);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const setHeaders: { [name: string]: string } = {
			'x-proxy-request': 'frontend',
			'x-operator-id': this.configService.operatorId || '',
		};

		const meta = getMetaData(req.params);
		const authType = Utils.authTypeFromMeta(meta);

		let noToken = false;
		if (!req.headers.has(HEADER_AUTH) && authType !== AuthType.NONE) {
			const token = this.tokenFromAuthType(authType);
			if (token)
				setHeaders[HEADER_AUTH] = `Bearer ${token}`;
			else
				noToken = true;
		}

		const handleNext = () => next.handle(req.clone({ setHeaders, reportProgress: true })).pipe(
			catchError(error => {
				if ((error instanceof HttpErrorResponseExt && error.handled) || error.status !== HttpStatusCode.Unauthorized)
					return throwError(error);
				else
					return this.handleUnauthorized(meta, error);
			}),
		);

		if (noToken && this.isOnline && authType === AuthType.USER) {
			this.authService.gotoLogin();
			return throwError(new HttpErrorResponse({
				error: 'No valid auth token found',
				status: HttpStatusCode.Unauthorized,
				// https://github.com/angular/angular/blob/3a60063a54d850c50ce962a8a39ce01cfee71398/packages/common/http/src/response.ts#L357
				// HttpStatusCode defined as "const enum"
				statusText: getHTTPStatusText(HttpStatusCode.Unauthorized),
			}));
		}
		return handleNext();
	}
}
