import { IActionDto, KnoxRegistrationActionHelper, KNOX_ACTIONS } from "@aex/security/shared";

export class ClientInterfaceKnoxActionHelper{

  public static getSystemName() : string {
    return 'Client Interface';
  }
  public static getSystemActions() : IActionDto[] {
    return [
      KnoxRegistrationActionHelper.RegisterAction(KNOX_ACTIONS.ManageCustomers, "Manage Customer", "Manage Customer"),
      KnoxRegistrationActionHelper.RegisterAction(KNOX_ACTIONS.ReadCustomers, "Read Customer", "Read Customer"),
    ]
  }
}