import { AexNgToolboxModule, DEFAULT_LOADER_CONFIG, MockModule, TOASTER_CONFIG } from '@aex/ngx-toolbox';
import { ProductService } from '@aex/shared/apis';
import { ENVIRONMENT_CONFIG_TOKEN, ErrorInterceptor, IEnvironment } from '@aex/shared/common-lib';
import { ApiInterceptor, AuthService, BaseConfigService, ConfigService, DbService, LocaleService, PwaService } from '@aex/shared/root-services';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faAngleDown,
	faAngleUp,
	faArrowCircleDown,
	faArrowCircleUp,
	faArrowLeft,
	faArrowRight,
	faCheck, faCheckCircle,
	faChevronCircleUp,
	faChevronDown,
	faChevronUp,
	faClock,
	faCreditCard,
	faDownload,
	faMapMarkedAlt,
	faMapMarkerAlt,
	faMoneyBillAlt,
	faPlus,
	faPlusCircle,
	faQuestion,
	faQuestionCircle,
	faSortDown,
	faSortUp,
	faThumbsDown,
	faThumbsUp,
	faTimesCircle,
	faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import { ClientInterfaceAuthInterceptor } from '@shared/interceptors/client-interface-auth-interceptor';
import { ClientInterfaceAppConfigLoaderService } from '@shared/services/client-interface-app-config-loader.service';
import { ClientInterfaceAuthService } from '@shared/services/client-interface-auth.service';
import { SharedComponentsModule } from '@shared/shared-components.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CamelizePipe } from 'ngx-pipes';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ChartModule } from 'primeng/chart';
import { zip } from 'rxjs';
import { environment } from '../environments/environment';
import { MockRegistrations } from '../mock/mock';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SecurityLoginHandlerService } from '@aex/security/authentication';
// import { ClientInterfaceSecurityHandlerService } from '@shared/services/client-interface-security-login-handler.service';

export const ENV_CONFIG: Partial<IEnvironment> = environment;

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		ChartModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		FlexLayoutModule,
		FontAwesomeModule,
		HttpClientModule,
		MockModule.forRoot(
				{
					enabled: environment.mocksEnabled,
					serverUrls: [
						environment.ispServerUrl,
						environment.fnoServerUrl,
						environment.securityServiceUrl,
						environment.paymentServerUrl,
						environment.purchaseOrderServerUrl,
					],
				}),
		NgxUiLoaderModule.forRoot(DEFAULT_LOADER_CONFIG),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.useServiceWorker }),
		SharedComponentsModule,
		ToastContainerModule,
		ToastrModule.forRoot(TOASTER_CONFIG),
		AexNgToolboxModule.forRoot({ httpTimeout: environment.httpTimeout }),
		AgmCoreModule.forRoot({
			libraries: ['geometry', 'places'],
		}),
		TimepickerModule.forRoot(),
		BsDatepickerModule.forRoot(),
	],
	providers: [
		CamelizePipe,
		DbService,
		PwaService,
		ConfigService,
		ProductService,
		Title,
		ClientInterfaceAppConfigLoaderService,
		{
			provide: AuthService,
			useExisting: ClientInterfaceAuthService,
		},
		SecurityLoginHandlerService,
		// {
		// 	provide: SecurityLoginHandlerService,
		// 	useExisting: ClientInterfaceSecurityHandlerService,
		// },
		{
			provide: BaseConfigService,
			useClass: ConfigService,
		},
		// These services must be initialised straight away
		{
			provide: APP_INITIALIZER, deps: [DbService, MockRegistrations],
			useFactory: (db: DbService) => () => db.initDb().toPromise(), multi: true,
		},
		{
			provide: APP_INITIALIZER,
			deps: [ClientInterfaceAppConfigLoaderService],
			useFactory: (startUp: ClientInterfaceAppConfigLoaderService) => () => zip(startUp.loadAppConfig(), startUp.loadQuestionsWrapper()).toPromise(),
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ClientInterfaceAuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		// { provide: ErrorHandler, useClass: GlobalErrorHandler },
		{
			provide: LOCALE_ID,
			deps: [LocaleService],
			useFactory: (localeService: LocaleService) => localeService.getLocale(),
		},
		{ provide: ENVIRONMENT_CONFIG_TOKEN, useValue: ENV_CONFIG },
	],
	bootstrap: [AppComponent],
})
export class AppModule {

	constructor(library: FaIconLibrary) {
		library.addIcons(
				faArrowLeft,
				faArrowRight,
				faChevronCircleUp,
				faMapMarkerAlt,
				faMapMarkedAlt,
				faAngleUp,
				faAngleDown,
				faThumbsUp,
				faThumbsDown,
				faArrowCircleDown,
				faPlusCircle,
				faArrowCircleUp,
				faQuestion,
				faClock,
				faMoneyBillAlt,
				faCheck,
				faChevronUp,
				faChevronDown,
				faDownload,
				faCreditCard,
				faUniversity,
				faQuestionCircle,
				faSortDown,
				faSortUp,
				faCheckCircle,
				faPlus,
				faTimesCircle,
		);
	}

}
