import { IPagedResponse } from '@aex/ngx-toolbox';
import { AuthType, IAdvert, ParamMetaData } from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdvertApi } from '../api';

@Injectable()
export class AdvertService {

	constructor(
			private readonly http: HttpClient,
	) { }

	public loadAds(loader?: string): Observable<IAdvert[]> {
		return this.http.get<IPagedResponse<IAdvert>>(AdvertApi.ads, {
			params: new ParamMetaData({authToken: AuthType.PROXIED, loader}),
		}).pipe(map(a => a.items));
	}

}
