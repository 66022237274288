import { filterNonNil } from '@aex/ngx-toolbox';
import { ICustomer, KEY_CURRENT_USER } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerUserService {

	protected readonly currentUserSubject = new BehaviorSubject<ICustomer>(null);
	public readonly currentUserStream = this.currentUserSubject.asObservable();

	public set currentUser(value: ICustomer) {
		this.currentUserSubject.next(value);
		this.configService.store(KEY_CURRENT_USER, value);
	}

	public get currentUser(): ICustomer {
		return this.currentUserSubject.getValue();
	}

	constructor(protected readonly configService: ConfigService) {
		this.configService.configValuesStream.pipe(filterNonNil()).subscribe(() => {
			this.currentUserSubject.next(this.configService.retrieveObject(KEY_CURRENT_USER));
		});
	}

	public mergeCurrentUser(value: ICustomer): void {
		this.currentUser = merge(this.currentUser, value);
	}
}
