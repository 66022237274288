<ng-container *ngIf="configService.configStream | async as config">
	<div [ngClass]="showNewLook ? 'container-fluid bg-header d-flex flex-row align-items-center' : 'container-grid'">
		<nav class="navbar navbar-inverse navbar-expand-md" [ngClass]="showNewLook ? 'navbar-dark w-100 bg-transparent' : 'navbar-light'">
			<div class="d-flex flex-row justify-content-start align-items-center md:gap-4" (click)="clickHomeLogo()" *ngIf="showNewLook; else originalLogo">
				<div class="second-logo">
				</div>

				<div class="d-flex flex-column md:flex-row justify-content-center align-items-center md:gap-4">
					<strong class="font-size-responsive">Powered by</strong>

					<div class="first-logo">
					</div>
				</div>
			</div>

			<ng-template #originalLogo>
				<div class="home-logo clickable" (click)="clickHomeLogo()"></div>
			</ng-template>

			<button type="button" class="navbar-toggler custom-toggler" data-toggle="collapse"
				data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="navbar-collapse collapse" id="navbarCollapse">
				<ul id="sideNav" class="navbar-nav ml-lg-auto p-3 lg:p-0">
					<li class="d-inline d-md-none">
						<button data-toggle="collapse" data-target="#navbarCollapse"
							class="close float-right">&times;</button>
					</li>
					<li *ngIf="configValues.displayWelcomeOnSideBar" class="nav-item nav-link uppercase d-md-none">
						<p-divider class="text-white d-md-none"></p-divider>
						<a class="nav-item nav-link nav-item-home uppercase">Welcome to {{ showFnoName }}</a>
						<p-divider class="text-white d-md-none"></p-divider>
					</li>
					<li *ngFor="let route of headerRoutes" class="nav-item nav-link uppercase">
						<a class="nav-item nav-link nav-item-home uppercase" (click)="navigateConfirm(route)"
							*ngIf="!showNewLook || route?.link">
							{{route.name}}
						</a>

						<button class="btn-app btn-app-responsive md:btn-app-primary" (click)="navigateConfirm(route)" *ngIf="showNewLook && route?.button">
							{{route.name}}
						</button>
					</li>
					<li class="nav-item nav-link uppercase"  *ngIf="!configValues.hideDownloadOption && !installPrompt && !showNewLook">
						<button
							class="btn btn-outline-light uppercase float-left"
							(click)="navigateConfirm(downloadAppRoute)">Download
						</button>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</ng-container>