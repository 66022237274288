import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
	IKnoxEnableTwoFactorAuthenticationRequestDto,
	IKnoxEnableTwoFactorAuthenticationResponseDto,
	IKnoxMfaSecretResponseDto,
	ISendTwoFactorAuthenticationMessageRequestDto, ISendTwoFactorMessageResponseDto,
	KnoxAuthApiService,
	TWO_FACTOR_METHODS,
} from "@aex/security/shared";
import { isNil } from "lodash";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
	selector: 'app-enable-two-factor-authentication-method',
	templateUrl: './enable-two-factor-authentication-method.component.html',
	styleUrls: ['./enable-two-factor-authentication-method.component.scss'],
})
export class EnableTwoFactorAuthenticationMethodComponent {

	public readonly QR_CODE_WIDTH: number = 256;

	private readonly issuer: string= 'AutomationExchange';
	private _loginId: string;

	public get loginId(): string
	{
		return this._loginId;
	}
	@Input() public set loginId(value: string){
		this._loginId = value;
		if (isNil(this.email))
			this.email = value;
	}

	@Input()  public selectedMethod: string = '';

	@Output() onSuccessfulSave: EventEmitter<void> = new EventEmitter();

	private _email: string;
	public get email(): string	{
		return this._email;
	}
	public set email(value: string)	{
		this._email = value;
		this.disableEmail = false;
	}

	private _mobilePhoneNumber: string;
	public get mobilePhoneNumber(): string	{
		return this._mobilePhoneNumber;
	}
	public set mobilePhoneNumber(value: string)	{
		this._mobilePhoneNumber = value;
		this.disableSms = false;
	}

	public disableEmail: boolean;
	public disableSms: boolean;

	public verificationCode: string;
	public multiFactorSecret: IKnoxMfaSecretResponseDto = null;
	public recoveryCodes:string[] = [];
	public isAuthenticationEnabled: boolean = false;
	public qrData: string = '';
	public twoFactorId: string = '';

	public get isAuthenticatorMethod(): boolean{
		return this.selectedMethod === TWO_FACTOR_METHODS.Authenticator;
	}
	public get isEmailMethod(): boolean{
		return this.selectedMethod === TWO_FACTOR_METHODS.Email;
	}
	public get isSmsMethod(): boolean{
		return this.selectedMethod === TWO_FACTOR_METHODS.SMS;
	}

	constructor(
			private readonly knoxAuthApiService : KnoxAuthApiService,
			private readonly toastrService: ToastrService,
	) {
		this.knoxAuthApiService.getMultiFactorSecret().subscribe(
				(responseDto: IKnoxMfaSecretResponseDto) => {
					this.multiFactorSecret = responseDto;
					this.buildQrData();
				},
		)
	}

	private sendTwoFactorOneTimeCode(
		requestDto: ISendTwoFactorAuthenticationMessageRequestDto,
	): Observable<ISendTwoFactorMessageResponseDto>
	{
		return this.knoxAuthApiService.sendTwoFactorAuthenticationMessage(
				requestDto,
		).pipe(
				map(
				(responseDto: ISendTwoFactorMessageResponseDto): ISendTwoFactorMessageResponseDto => {
					this.twoFactorId = responseDto.two_factor_id;
					return responseDto;
				},
			),
		);
	}

	public sendEmailOneTimeCode():void{
		const requestDto: ISendTwoFactorAuthenticationMessageRequestDto = {
			login_id: this.loginId,
			method: this.selectedMethod,
			email: this.email,
		};

	  this.sendTwoFactorOneTimeCode(
			  requestDto,
	  ).subscribe(
			  (_)=> {
					this.disableEmail = true;
				  this.toastrService.info(`An email with a verification code has been sent to your selected email.`);
			  },
	  );
	}

	public sendSmsOneTimeCode():void{
		const requestDto: ISendTwoFactorAuthenticationMessageRequestDto = {
			login_id: this.loginId,
			method: this.selectedMethod,
			mobile_phone_number: this.mobilePhoneNumber,
		};

		this.sendTwoFactorOneTimeCode(
				requestDto,
		).subscribe(
				(_)=> {
					this.disableEmail = true;
					this.toastrService.info(`An sms with a verification code has been sent to your provided mobile number.`);
				},
		);
	}

	public enableTwoFactor(): void{
		const requestDto: IKnoxEnableTwoFactorAuthenticationRequestDto = {
			login_id: this.loginId,
			code: this.verificationCode,
			secret_base32_Encoded: this.multiFactorSecret.secret_base32_encoded ?? '',
			method: this.selectedMethod,
			email: this.email,
			mobile_phone_number: this.mobilePhoneNumber,
		  two_factor_id: this.twoFactorId,
		};
		this.knoxAuthApiService.enableTwoFactorAuthentication(
				requestDto,
		).subscribe(
				(responseDto: IKnoxEnableTwoFactorAuthenticationResponseDto)=> {
					this.recoveryCodes = responseDto.recovery_codes;
					this.toastrService.success(`Successfully enabled ${this.selectedMethod} authentication`);
					this.isAuthenticationEnabled = true;
					if (this.selectedMethod !== TWO_FACTOR_METHODS.Authenticator)
						this.onSuccessfulSave.emit();
				},
		);
	}
	public buildQrData():void{
		// https://github.com/google/google-authenticator/wiki/Key-Uri-Format#issuer
		this.qrData = `otpauth://totp/${this.issuer}:${this.loginId}?secret=${this.multiFactorSecret.secret_base32_encoded}&issuer=${this.issuer}`;
	}

	public closeSuccess(): void{
  	this.onSuccessfulSave.emit();
	}
}
