import { Component } from '@angular/core';
import {BaseConfigService} from "@aex/shared/root-services";
import {KEY_LOGIN_ID} from "@aex/shared/common-lib";
import {Router} from "@angular/router";

@Component({
  selector: 'app-two-factor-init',
  templateUrl: './two-factor-init.component.html',
  styleUrls: ['./two-factor-init.component.scss'],
})
export class TwoFactorInitComponent{

	public loginId: string;

  constructor(
			private readonly router: Router,
			private readonly configService: BaseConfigService,
  ) {
		this.loginId = this.configService.retrieve(KEY_LOGIN_ID);
  }

	onSuccessfulSave(){
		this.router.navigate(['home']);
	}

}
