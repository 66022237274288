import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ConfigService } from './config.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gtag: any;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	constructor(
		private readonly conf: ConfigService,
	) { }
	public sendConfigInTags(urlAfterRedirects: string) {
		gtag('config', this.conf.configValues.googleAnalyticsKey, {
			page_path: urlAfterRedirects,
		});
		if(this.conf.configValues.hasGoogleAnalytics4)
			gtag('config', this.conf.configValues.googleAnalytics4Key, {
				page_path: urlAfterRedirects,
			});
	}

	public initialize(): void {
		// dynamically add analytics scripts to document head
		if(this.conf.configValues.hasGoogleAnalytics)
			this.addGtagScriptToHeader(this.conf.configValues.googleAnalyticsKey);

		if(this.conf.configValues.hasGoogleAnalytics4)
			this.addGtagScriptToHeader(this.conf.configValues.googleAnalytics4Key);
	}

	// use gtag.js to send Google Analytics Events
	public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) : void {
		gtag('event', action, {
			...(eventCategory && { event_category: eventCategory }),
			...(eventLabel && { event_label: eventLabel }),
			...(value && { value }),
		});
	}

	public addGtagScriptToHeader(key: string){
		try {
			const head = document.getElementsByTagName('head')[0];
			const gtagScript = document.createElement('script');
			const url = 'https://www.googletagmanager.com/gtag/js?id=';
			gtagScript.src = `${url}${key}`;
			gtagScript.async = true;
			gtagScript.src = `${url}${key}`;
			gtagScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${url}${key}', {'send_page_view': false});`;
			head.insertBefore(gtagScript, head.children[1]);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			throwError('Error adding Google Analytics', e);
		}
	}
}
