import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DashboardViewService {

	private readonly currentViewSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public currentViewStream: Observable<string> = this.currentViewSubject.asObservable();

	constructor() { }

	public set currentView(view: string) {
		this.currentViewSubject.next(view);
	}

	public get currentView(): string {
		return this.currentViewSubject.getValue();
	}
}
