import {
	getLocalStoreItem,
	NavigationService,
	StorageService,
} from '@aex/ngx-toolbox';
import {
	COUNTRY_CODE,
	ENVIRONMENT_CONFIG_TOKEN, IEnvironment, IHeaderFooter, IInterestQuestion, SERVER_CONFIG,
} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseConfigService } from './base-config.service';

@Injectable()
export class ConfigService extends BaseConfigService {

	private readonly configSubject = new BehaviorSubject<IHeaderFooter>(null);
	public readonly configStream = this.configSubject.asObservable();

	public questionList: IInterestQuestion[] = [];

	public readonly breakpointTablet = 960;

	constructor(
		http: HttpClient,
		navigationService: NavigationService,
		storageService: StorageService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
	) {
		super(http, navigationService,storageService, environment);
	}

	public get configBehaviorSubject(): BehaviorSubject<IHeaderFooter> {
		return this.configSubject;
	}

	public get isPaymentSystem(): boolean {
		return this.configValues.paymentSystem;
	}

	public get isISP(): boolean {
		return this.configValues.isIsp;
	}

	public get minScheduleDays(): number {
		return this.configValues.minScheduleDays;
	}

	public get supportNumber(): string {
		return this.startUpData?.supportNumber;
	}

	public get kmlUrl(): string {
		return SERVER_CONFIG.kmlServerUrl;
	}
	public get popiCheckboxEnabled(): boolean {
		return this.startUpData?.popiCheckboxEnabled;
	}

	public get showCapType(): boolean {
		return this.configValues.showCapType ?? true;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// public store(key: string, value: any): void {
	// 	this.storageService.store(key, value, this._configValues?.encryptLocalStore);
	// }
	//
	// public retrieve(key: string): string {
	// 	return this.storageService.retrieve(key, this._configValues?.encryptLocalStore);
	// }
	//
	// public retrieveObject<T>(key: string): T {
	// 	return this.storageService.retrieveObject(key, this._configValues?.encryptLocalStore);
	// }

	protected getStartupRoute(): string{
		return this.startUpData.startUrl ?? 'landing';
	}

	public get getCountryCode(): string { return getLocalStoreItem(`${ this._fnoName } - ${ COUNTRY_CODE }`); }

}
