import {ICardCheckout, ICardCheckOutResponseBase} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CardPaymentApi } from '../api';

@Injectable({
	providedIn: 'root',
})
export class CardPaymentService {

	constructor(private readonly http: HttpClient) { }

	public prepareCheckout(amount: number, providerId: string, mediumDetailId: string): Observable<ICardCheckOutResponseBase> {
		const checkout: ICardCheckout = {
			amount,
			provider_Id: providerId,
			medium_Detail_Id: mediumDetailId,
		};
		return this.http.post<ICardCheckOutResponseBase>(CardPaymentApi.prepareCheckout, checkout);
	}
}
