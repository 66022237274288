import { IPagedResponse, isNonNull, WithDestroy } from '@aex/ngx-toolbox';
import { AuthType, IDeviceStatus, IProduct, IProvider, ParamMetaData, ProductStatus, ProductType, IService, IProductPriceOverride } from '@aex/shared/common-lib';
import { AuthService, DbService, MAX_PERCENT_BEFORE_TRUNCATE, STORE_PRODUCT, STORE_SERVICE } from '@aex/shared/root-services';
import { LatLngLiteral } from '@agm/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceBy, max, partition, unionBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ProductApi } from '../api';
import { catchDbStoreError } from './service-settings.service';

@Injectable()
export class ProductService extends WithDestroy() {

	constructor(
		private readonly http: HttpClient,
		private readonly db: DbService,
		protected readonly authService: AuthService,
	) {
		super();
		this.noZombie(authService.loggedInStream).pipe(filter(l => !l)).subscribe(() => this.bustCachesOnLogOff());
	}

	private _products: IProduct[];

	public getProvider(providerId: string): Observable<IProvider> {
		return this.http.get<IProvider>(ProductApi.provider(providerId), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getProviders(): Observable<IProvider[]> {
		return this.http.get<IPagedResponse<IProvider>>(ProductApi.providers, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}).withAllCount(),
		}).pipe(
			catchError(() => of({ items: []} as IPagedResponse<IProvider>)),
			map(r => r.items),
			);
	}

	public getProduct(productId: string): Observable<IProduct> {
		return this.http.get<IProduct>(ProductApi.product(productId), {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getProductPriceOverrides(serviceId :string): Observable<IProductPriceOverride[]> {
		return this.http.get<IPagedResponse<IProductPriceOverride>>(ProductApi.productPriceOverrides(), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }).set('service', serviceId).withAllCount(),
		}).pipe(
			catchError(() => of({ items: [] } as IPagedResponse<IProductPriceOverride>)),
			map(r => r.items),
		);
	}

	public getProductsByLocation(mapInfo: LatLngLiteral): Observable<IProduct[]> {
		const params = new ParamMetaData({ authToken: AuthType.PROXIED, handleError: 'products' })
			.set('visible_to_user', true)
			.set('type', ProductType.ISP)
			.withAllCount();
		return this.http.get<IPagedResponse<IProduct>>(ProductApi.productsByLocation(mapInfo), {params}).pipe(
			catchError(
					(error) =>{
						console.log('getProductsByLocation.error', error);
						return of({ items: []} as IPagedResponse<IProduct>)
					},
			),
			map(r => r.items),
		);
	}

	public getProductsFromApi(params: HttpParams): Observable<IProduct[]> {
			return this.http.get<IPagedResponse<IProduct>>(ProductApi.products, { params }).pipe(
				catchError(() => of({ items: [] } as IPagedResponse<IProduct>)),
				map(r => {
					return [...r.items].sort((a, b) => {
						// Sort by provider name
						const providerCompare = a.provider.localeCompare(b.provider);
						if (providerCompare !== 0)
							return providerCompare;
						// Sort by speed_down if provider names are equal
						return a.speed_down - b.speed_down;
					});
				}),
			);
	}

	private getProductsFromDb(): Observable<IProduct[]> {
		// @ts-expect-error: Argument of type 'string' is not assignable to parameter of type 'never'
		return this.db.selectAll(STORE_PRODUCT).pipe(
				switchMap(fromDbStore => {
					const maxDate = fromDbStore.reduce<Date>(
							(result, product) => max([result, product.created_at, product.updated_at].filter(isNonNull).map(d => new Date(d))),
							null,
					);

					const params = new ParamMetaData({ authToken: AuthType.PROXIED, handleError: 'products' })
						.set('visible_to_user', true)
						.set('type', ProductType.ISP)
						.withAllCount();

					// Remove the timeout if we are fetching all info
					if (!maxDate)
						params.meta.timeout = false;

					return this.getProductsFromApi(params)
						.pipe(
							map(fromApi => {
								console.log('products from api...', fromApi);
								// Split into 2 lists, updates & deletes
								const [toBeUpdated, toBeDeleted] = partition(fromApi, p => !p.deleted_at && p.status === ProductStatus.ACTIVE);
								// Compute final list
								const finalList = differenceBy(unionBy(toBeUpdated, fromDbStore, p => p.id), toBeDeleted, p => p.id);
								// Update the DB, we can let this run on it's own - it is atomic, and we don't really care whether it succeeds
								// if this fails, the next time this code runs it will do the same thing
								const l1: number = toBeUpdated.length;
								const l2: number = toBeDeleted.length;
								if (fromDbStore.length && (l1 + l2) / fromDbStore.length <= MAX_PERCENT_BEFORE_TRUNCATE)
									// @ts-expect-error: Argument of type 'string' is not assignable to parameter of type 'never'
									this.db.updateAndDelete(STORE_PRODUCT, toBeUpdated, toBeDeleted.map(p => p.id)).subscribe();
								else
									// @ts-expect-error: Argument of type 'string' is not assignable to parameter of type 'never'
									this.db.replaceAll(STORE_PRODUCT, finalList).subscribe();

								return finalList;
							}),
							tap(p => this._products = p),
							catchDbStoreError(fromDbStore),
					);
				}),
		);
	}

	public getProducts(): Observable<IProduct[]> {
		// Check in product cache for latest date, then get products after that date
		return this._products
				? of(this._products)
				: this.getProductsFromDb();
	}

	private bustCachesOnLogOff(): void {
		// @ts-expect-error: Argument of type 'string' is not assignable to parameter of type 'never'
		this.db.truncate(STORE_SERVICE);
	}

	public getDeviceFromSerial(serialNumber: string): Observable<IDeviceStatus> {
		return this.http.get<IDeviceStatus>(ProductApi.deviceFromSerial(serialNumber), {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getServiceFromSerial(serialNumber: string): Observable<IService[]> {
		return this.http.get<IPagedResponse<IService>>(ProductApi.serviceFromSerial(serialNumber), {
		params: new ParamMetaData({authToken: AuthType.USER}, {status: 'Active'}),
		}).pipe(map(r => r.items));
	}

	public getDevicesFromSerial(serialNumber: string): Observable<IDeviceStatus> {
		return this.http.get<IDeviceStatus>(ProductApi.deviceFromSerial(serialNumber), {
		params: new ParamMetaData({authToken: AuthType.USER}),
		});
	}
}
