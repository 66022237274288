import { IPagedResponse } from '@aex/ngx-toolbox';
import { APP_ROUTES, AuthType, IApplicationUser, INotification, ParamMetaData } from '@aex/shared/common-lib';
import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { remove } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationApi } from '../api';
import { CustomerService } from './customer.service';

@Injectable()
export class NotificationService {

	private readonly notificationsSubject = new BehaviorSubject<INotification[]>(null);
	public readonly notificationsStream = this.notificationsSubject.asObservable();
	private readonly successStatusCode = 200;

	constructor(
			private readonly http: HttpClient,
			private readonly loc: PlatformLocation,
			public readonly customerService: CustomerService,
			private readonly toast: ToastrService,
	) {}

	public getNotifications(customerId: string, loader: string): Observable<INotification[]> {
		return this.http.get<IPagedResponse<INotification>>(NotificationApi.notifications, {
			params: new ParamMetaData({loader}, {recipient: customerId, dismissed: false}),
		}).pipe(
				map(n => n.items),
				tap(n => this.notificationsSubject.next(n)),
		);
	}

	public dismissNotification(notificationId: string): Observable<void> {
		return this.http.put<void>(NotificationApi.notification(notificationId), {notification: {dismissed: true}}, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		}).pipe(
				tap(() => this.notificationsSubject.next(remove(this.notificationsSubject.getValue(), {id: notificationId}))),
		);
	}

	public sendAccountConfirmationLink(email: string, referralCode: string = ''): Observable<void> {
		const user = new IApplicationUser();
		const clientUrl = this.loc.href.substring(0, this.loc.href.lastIndexOf('/'));

		user.clientUrl = `${clientUrl}/${APP_ROUTES.accountConfirmation.name}`;
		user.userEmail = email;
		user.userReferralCode = referralCode;

		this.customerService.sendAccountCreationLink(user).subscribe(res => {
					if (res.statusCode === this.successStatusCode)
						this.toast.info(`Account confirmation email sent, please check your mails`);
					else
						this.toast.info(`An error occurred while sending confirmation email`);
				});

		return of(null);
	}
}
