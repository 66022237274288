import { Injectable} from "@angular/core";
import { Resolve, Router} from "@angular/router";
import { Observable} from "rxjs";
import { 
  ILoginResponseDto, 
  ITwoFactorMethodDto, 
  KnoxAuthApiService,
} from "@aex/security/shared";

@Injectable()
export class GetUserTwoFactorMethodsResolver implements Resolve<ITwoFactorMethodDto[]> {

	constructor(
    private readonly knoxAuthApiService: KnoxAuthApiService, 
    private readonly router: Router
  ) { }

	public resolve(): Observable<ITwoFactorMethodDto[]> {
		const loginResponse:ILoginResponseDto = this.router.getCurrentNavigation().extras.state as ILoginResponseDto;
		const userId: string = loginResponse.email;
		return this.knoxAuthApiService.getUserTwoFactorMethods(userId);
	}
}
