import {CustomerService} from '@aex/shared/apis';
import {
	// IAuthResponse,
	ICustomer,
} from '@aex/shared/common-lib';
import { Injectable } from '@angular/core';
import { Observable, } from 'rxjs';
import {map, switchMap,} from 'rxjs/operators';
import {AuthService} from "./auth.service";
import {ILoginResponseDto} from "@aex/security/shared";

@Injectable(
	{providedIn:'root'}
)
export class CustomerAuthService {

	// protected readonly loggedInSubject = new BehaviorSubject<boolean>(null);
	// public readonly loggedInStream = this.loggedInSubject.asObservable().pipe(filterNonNil());
	// private readonly _authOverride: Subject<void>;
	// public readonly authOverride: Observable<void>;
	//
	constructor(
		protected readonly authService: AuthService,
		protected readonly customerService: CustomerService,
	) {
	}

	// constructor(
	// 	protected readonly http: HttpClient,
	// 	protected readonly router: Router,
	// 	protected readonly configService: BaseConfigService,
	// 	@Inject(ENVIRONMENT_CONFIG_TOKEN) protected readonly environment: IEnvironment,
	// ) {
	// 	this._authOverride = new Subject();
	// 	this.authOverride = this._authOverride.asObservable();
	// }
	//
	// protected _authToken: string;
	// public get authToken(): string {
	// 	this._authToken = this._authToken ?? this.getToken(KEY_AUTH_TOKEN);
	// 	if (this._authToken && isTokenExpired(this._authToken))
	// 		this.bustCaches().subscribe();
	//
	// 	return !this._authToken && !this.environment.mocksEnabled ? null : this._authToken;
	// }
	//
	// protected _providerToken: string;
	// public get providerToken(): string {
	// 	this._providerToken = this._providerToken || this.getToken(KEY_PROVIDER_TOKEN);
	// 	return !this._providerToken || isTokenExpired(this._providerToken) && !this.environment.mocksEnabled ? null : this._providerToken;
	// }
	//
	// public set providerToken(value: string) {
	// 	this.setProviderToken(value);
	// }
	//
	// protected setProviderToken(value: string) {
	// 	this._providerToken = value;
	// }
	//
	// protected getToken(key: string): string {
	// 	return key;
	// }
	//
	// public get isLoggedIn(): boolean {
	// 	return !!this.authToken;
	// }
	//
	// public setAuthToken(value: string): void {
	// 	this._authToken = value;
	// }
	//
	// public overrideAuth(authToken: string, providerToken: string): void {
	// 	this.setAuthToken(authToken);
	// 	this.providerToken = providerToken;
	// 	this._authOverride.next();
	// }
	//
	// public setProxyToken(value: string): void {
	// 	window.parent.postMessage({proxyToken: value}, '*');
	// }
	//
	// public loginClientGrantCredentialAuthToken(): Observable<string>	{
	// 	const loginRequestDto: ILoginRequestDto = {
	// 		client_id: this.environment.clientId,
	// 		client_secret: this.environment.clientSecret,
	// 		grant_type:"client_credentials",
	// 	};
	// 	return this.doKnoxLogin(loginRequestDto).pipe(
	// 		switchMap(
	// 			(response: ILoginResponseDto): string=> {
	// 				this.providerToken = response.auth_token;
	// 				return response.auth_token;
	// 			},
	// 		),
	// 	);
	// }
	//
	// private doKnoxLogin(requestDto: ILoginRequestDto, loader?:LoaderType): Observable<ILoginResponseDto>
	// {
	// 	let headerID = this.configService.operator.toUpperCase();
	//
	// 	return this.http.post<ILoginResponseDto>(
	// 		KnoxApi.knoxUrlGeneral(Urls.Auth),
	// 		requestDto,
	// 		{
	// 			headers: new HttpHeaders()
	// 				.set('X-OPERATOR-ID', headerID)
	// 				.set('X-ORG-UNIT-ID', headerID),
	// 			params: new ParamMetaData({
	// 				handleError: 'knox',
	// 				authToken: AuthType.NONE,
	// 				loader,
	// 			}),
	// 		},
	// 	);
	// }
	//
	// public authLogin(
	// 	username: string,
	// 	password: string,
	// 	loader?: LoaderType,
	// ): Observable<ILoginResponseDto> {
	// 	const loginRequestDto: ILoginRequestDto = {
	// 		login_id: username,
	// 		password: password,
	// 	};
	// 	return this.doKnoxLogin(loginRequestDto,loader).pipe(
	// 		tap(( loginResponseDto: ILoginResponseDto) => {
	// 			this.processLoginResponse(username, loginResponseDto);
	// 		}),
	// 	);
	// }
	//
	// public processLoginResponse(username: string, loginResponseDto: ILoginResponseDto): void {
	// 	if (loginResponseDto.is_successful) {
	// 		this.configService.store(KEY_USER_ID, loginResponseDto.user_id);
	// 		this.configService.store(KEY_LOGIN_ID, username);
	// 		this.setAuthToken(loginResponseDto.auth_token);
	// 		this.setProviderToken(loginResponseDto.auth_token);
	// 	}
	// }
	//
	// public login(username: string, password: string, loader?: LoaderType): Observable<IAuthResponse> {
	// 	return this.authLogin(username, password, loader);
	// }

	// public loginCustomerAuthToken(username: string, password: string): Observable<string> {
	// 	return this.login(username, password).pipe(
	// 		map(res => {
	// 			this.setAuthToken(res.auth_token);
	// 			return res.auth_token;
	// 		}),
	// 	);
	// }

	public doGetLoginCustomer(username: string): Observable<ICustomer>{
		return this.customerService.getCustomerPremises(username);
	}
//# auth.service -> Login stuff
//#
//# move to customer-auth.service

// customer-auth.service -> auth (to login)
//                        -> do customer premise...
	public loginCustomer(username: string, password: string): Observable<ICustomer> {
		return this.authService.login(username, password).pipe(
			map(
				(response : ILoginResponseDto) : boolean => {
					// this.authService.isLoggedIn = this.securityLoginHandlerService.determineLoginResponse(response, username, '');
					return this.authService.isLoggedIn = response.is_successful && response.successful_status == 200;
				}
			),
			switchMap(() : Observable<ICustomer> => {
				// Handle IResponse
				// Two factor?
				return this.doGetLoginCustomer(username);
			})
		)
	}

	// public loginProviderAuthToken(username: string, password: string, loader?: LoaderType): Observable<string> {
	// 	if (SERVER_CONFIG.serverUrl)
	// 		return this.login(username, password, loader).pipe(
	// 			map(res => {
	// 				this.providerToken = res.auth_token;
	// 				return res.auth_token;
	// 			}),
	// 		);
	// 	else // duct tape fix
	// 		return of(null);
	// }
	//
	// public doGetLoginProvider(token: string): Observable<string>{
	// 	if (token) {
	// 		// do nothing by default
	// 	}
	//
	// 	return of(null);
	// }
	//
	// public loginProvider(): Observable<string> {
	// 	return this.loginProviderAuthToken(
	// 		this.environment.operatorCredentials.username,
	// 		this.environment.operatorCredentials.password,
	// 		false,
	// 	).pipe(
	// 		switchMap((token: string): Observable<string> => {
	// 			return this.doGetLoginProvider(token);
	// 		}),
	// 	);
	// }
	//
	// public loginBoth(username: string, password: string): Observable<ICustomer> {
	// 	return this.loginProvider().pipe(
	// 		switchMap(() => this.loginCustomer(username, password)),
	// 	);
	// }
	//
	// public getProxyToken(): Observable<IAuthResponse> {
	// 	return this.http.post<IAuthResponse>(ProxyApi.proxy, null, {
	// 		params: new ParamMetaData({authToken: AuthType.USER}),
	// 	}).pipe(
	// 		tap(res => {
	// 			if (!res.auth_token)
	// 				throw new Error('No authentication token returned');
	// 			else
	// 				this.setProxyToken(res.auth_token);
	// 		}),
	// 	);
	// }
	//
	// public bustCaches(): Observable<void> {
	// 	this.setAuthToken(null);
	// 	this.providerToken = null;
	// 	return clearAllDataCaches();
	// }
	//
	// public logout(): Observable<void> {
	// 	this.loggedInSubject.next(false);
	// 	return this.bustCaches();
	// }
	//
	// public gotoLogin(returnUrl?: string) : void {
	// 	this.router.navigate([APP_ROUTES.login.path, {returnUrl: returnUrl || this.router.url}]).then();
	// }
	//
	// protected parseToken(token: string) : ITokenResult {
	// 	return token ? JSON.parse(atob(token.split('.')[1])) : null;
	// }
	//
	// public getClaim(name: string): string {
	// 	let token = this.authToken;
	// 	if (_.isEmpty(token))
	// 		token = this.providerToken;
	//
	// 	if (this.knoxEnabled) {
	// 		const tokenObject = token ? JSON.parse(atob(token.split('.')[1])) : null;
	// 		return tokenObject[name]
	// 	}
	//
	// 	return this.parseToken(token)?.claims.map(c => c.split(':')).find(c => c[0] === name)[1];
	// }
	//
	// public getOperatorIdClaim(): string {
	// 	return this.getClaim(OPERATOR_ID_CLAIM);
	// }
	//
	// public getUserIdClaim(): string {
	// 	return this.getClaim(USER_ID_CLAIM);
	// }
	//
	// public get roles(): string[] {
	// 	const parsedToken = this.parseToken(this.authToken);
	//
	// 	return parsedToken.claims
	// 		.filter(c => c.startsWith(ROLE_CLAIM_PREFIX))
	// 		.map(c => c.toLocaleLowerCase());
	// }
	//
	// public isInRole(role: string): boolean {
	// 	return this.roles.includes(role);
	// }
	//
	// public get knoxEnabled(): boolean{
	// 	return this.environment.knoxEnabled ?? false;
	// }
	//
	// public completeIdentityProviderLogin(identityProviderType: string, authorisationCode: string, redirectUri: string): Observable<ILoginResponseDto> {
	// 	const headerID = this.configService.operator.toUpperCase();
	// 	const url =  'auth/identity-provider/token';
	// 	const queryParams = `identityProviderLoginType=${ identityProviderType }&redirectUri=${redirectUri}&authorisationCode=${authorisationCode}`;
	// 	return this.http.get<ILoginResponseDto>(
	// 		KnoxApi.knoxUrlGeneral(`${url}?${queryParams}`),
	// 		{
	// 			headers: new HttpHeaders()
	// 				.set('X-OPERATOR-ID', headerID)
	// 				.set('X-ORG-UNIT-ID', headerID),
	// 			params: new ParamMetaData({
	// 				handleError: 'knox',
	// 				authToken: AuthType.NONE,
	// 			}),
	// 		},
	// 	);
	// }
}
