import {
	AuthType,
	ConfigurationMappingTypeNumber,
	ConfigurationTypeNumber,
	IBank,
	IBankAccountDetail,
	IBillingSchemeConfiguration,
	ICardResponse,
	IMediumDetailDto,
	IMediumDetailInsertDto,
	IPaymentHistory,
	IPaymentLinkMediumDetail,
	IPaymentMedium,
	IPlanCollectionDayChangeResponse,
	IPurchasePlan,
	IServiceInfo,
	IBillingScheme,
	IStripeMediumExists,
	ParamMetaData,
	IConfiguration,
	ConfigurationMappingType,
	IPaymentLinkResponse,
	IOffSystemPayment,
	IPurchaseSource,
	IPurchaseDetail,
	ILineItem,
	IBuyNowRequest,
	IPurchase,
	IServiceStateAndAction,
	IPurchaseOrderRequest,
	IPurchaseResponse,
	IMediumAccountResponse,
} from '@aex/shared/common-lib';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { PaymentApi, PurchaseOrderApi, RulesEngineApi } from '../api';
import { IOffsetResponse, IPaymentResponse } from '../../../common-lib/src/interfaces/payment';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOrderService {

	private cachedServiceDetailsAndActions: { [serviceId: string]: Observable<IServiceStateAndAction> } = {};

	constructor(private readonly http: HttpClient) {
	}

	public getBanks(): Observable<IBank[]> {
		return this.http.get<IBank[]>(PaymentApi.banks, {
			params: new ParamMetaData({ authToken: AuthType.NONE, handleError: 'banks' }),
		});
	}

	public getPaymentMediums(customerId: string): Observable<IMediumAccountResponse[]> {
		return this.http.get<IMediumAccountResponse[]>(PurchaseOrderApi.paymentMediums, {
			params: new ParamMetaData({ authToken: AuthType.NONE, handleError: 'paymentInfo' })
				.set('userId', customerId),
		});
	}

	public getActivePaymentMediumForService(serviceId: string): Observable<IPaymentMedium> {
		return this.http.get<IPaymentMedium>(PurchaseOrderApi.activePaymentMediumForService, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'paymentMedium' })
				.set('serviceId', serviceId),
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public validateAccountDetails(bankingDetails: Partial<IBankAccountDetail>): Observable<any> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.post<any>(PurchaseOrderApi.bankAccountDetails, bankingDetails, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'bankDetails' }),
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public createCreditCardDetails(customerId: string): Observable<any> {
		const bankdetails = {
			customer_id: customerId,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.post<any>(PurchaseOrderApi.creditCardDetails, bankdetails, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'bankDetails' }),
		});
	}

	public createBuyNow(body: IBuyNowRequest): Observable<IPurchase> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.post<IPurchase>(PurchaseOrderApi.createBuyNowPurchaseOrder, body, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'purchaseOrder' }),
		});
	}

	public submitPurchaseForCollection(body: IPurchaseOrderRequest): Observable<IPurchaseResponse> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.post<IPurchaseResponse>(PurchaseOrderApi.submitPurchaseOrderForCollection, body, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'purchaseOrder' }),
		});
	}

	public getServiceDetailsAndActions(serviceId: string, refresh = false): Observable<IServiceStateAndAction> {
		if (this.cachedServiceDetailsAndActions[serviceId] && !refresh)
			// Get serviceInformation from cache if already exists
			return this.cachedServiceDetailsAndActions[serviceId];
		else {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const request = this.http.get<IServiceStateAndAction>(PurchaseOrderApi.getServiceDetailsAndActions(serviceId), {
				params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'purchaseOrder' }),
			});

			// add serviceInformation to cache
			this.cachedServiceDetailsAndActions[serviceId.toString()] = request;

			return request;
		}
	}

	public getPaymentHistory(serviceId: string): Observable<IPaymentHistory[]> {
		return this.http.get<IPaymentHistory[]>(PurchaseOrderApi.paymentHistory, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'paymentHistory' })
				.set('serviceId', serviceId),
		});
	}

	public updatePlanPaymentMedium(serviceId: string, mediumDetailId: string) {
		return this.http.put(PurchaseOrderApi.updatePlanPaymentMedium(serviceId, mediumDetailId), null, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'activatePaymentMedium' }),
		});
	}

	public getPlanByService(serviceId: string): Observable<IPurchasePlan> {
		return this.http.get<IPurchasePlan>(PurchaseOrderApi.getPlanByService(serviceId), {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'getPlanByService' }),
		});
	}

	public deletePaymentMedium(customerId: string, mediumDetailId: string) {
		return this.http.delete(PurchaseOrderApi.deletePaymentMedium(customerId, mediumDetailId), {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'deletePaymentMedium' }),
		});
	}

	public updateCardMediums(customerId: string, providerId: string, mediumDetailId: string, cardResourcePath: string, handleError: boolean = true) {
		const cardResponse: ICardResponse = {
			provider_Id: providerId,
			medium_detail_id: mediumDetailId,
			resource_path: cardResourcePath,
		};
		return this.http.put(PurchaseOrderApi.updateCardMediums(customerId), cardResponse, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError }),
		});
	}

	public updateDebitDay(serviceId: string, debitDay: number): Observable<IPlanCollectionDayChangeResponse> {
		return this.http.put<IPlanCollectionDayChangeResponse>(PurchaseOrderApi.setDebitDay(serviceId, debitDay), null);
	}

	public createPaymentLink(collectorId: string, customerId: string): Observable<IPaymentMedium> {
		const paymentLinkMediumDetailRequest: IPaymentLinkMediumDetail = {
			collector_id: collectorId,
			customer_id: customerId,
		};
		return this.http.post<IPaymentMedium>(PurchaseOrderApi.createPaymentLink, paymentLinkMediumDetailRequest);
	}

	public createStripePaymentMedium(
		customerId: string,
		mediumType: string,
		details: string,
		mediumStatusId: number,
	): Observable<IMediumDetailDto> {
		const mediumDetailInsertDto: IMediumDetailInsertDto = {
			customer_id: customerId,
			medium_type: mediumType,
			medium_status_id: mediumStatusId,
			details,
		};
		return this.http.post<IMediumDetailDto>(
			PurchaseOrderApi.createStripePaymentMedium,
			mediumDetailInsertDto,
			{
				params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'createPaymentMedium' }),
			},
		);
	}

	public createPaymentMedium(
		customerId: string,
		mediumType: string,
		details: string,
		mediumStatusId: number,
	): Observable<IMediumDetailDto> {
		const mediumDetailInsertDto: IMediumDetailInsertDto = {
			customer_id: customerId,
			medium_type: mediumType,
			medium_status_id: mediumStatusId,
			details,
		};
		return this.http.post<IMediumDetailDto>(
			PurchaseOrderApi.createPaymentMedium,
			mediumDetailInsertDto,
			{
				params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'createPaymentMedium' }),
			},
		);
	}

	public retryPurchasePayment(purchaseId: string): Observable<IPaymentResponse> {
		return this.http.post<IPaymentResponse>(PurchaseOrderApi.retryPurchasePayment(purchaseId), null);
	}

	public stripePaymentMediumExists(
		customerId: string,
		setupIntentId: string,
	): Observable<IStripeMediumExists> {

		return this.http.get<IStripeMediumExists>(PurchaseOrderApi.stripePaymentMediumExists, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'createPaymentMedium' })
				.set('customerId', customerId)
				.set('setupIntentId', setupIntentId),
		});
	}

	public createConfiguration(config: IBillingSchemeConfiguration): Observable<void> {
		return this.http.post<void>(RulesEngineApi.createConfiguration, config);
	}

	public editConfiguration(config: IBillingSchemeConfiguration, configId: number): Observable<void> {
		return this.http.put<void>(RulesEngineApi.updateConfiguration(configId), config);
	}

	public getConfiguration(configId: number): Observable<IConfiguration> {
		return this.http.get<IConfiguration>(RulesEngineApi.getConfiguration(configId));
	}

	public getBillingSchemeConfiguration(configId: number): Observable<IBillingSchemeConfiguration> {
		return forkJoin([
			this.getConfiguration(configId),
			this.getBillingScheme(configId),
		]).pipe(
			map(([config, billingScheme]) => {
				config.json = undefined;
				config.version = undefined;
				return { ...config, settings: billingScheme };
			}),
		);
	}

	public getBillingScheme(configId: number): Observable<IBillingScheme> {
		return this.http.get<IBillingScheme>(RulesEngineApi.getBillingScheme(configId));
	}

	public getBillingSchemeByType(configurationMappingType: ConfigurationMappingType, value: string): Observable<IBillingScheme> {
		return this.http.get<IBillingScheme>(RulesEngineApi.getBillingSchemeByType(configurationMappingType, value),
			{ params: new ParamMetaData({ authToken: AuthType.NONE }) });
	}

	public getConfigurations(): Observable<IConfiguration[]> {
		return this.http.get<IConfiguration[]>(RulesEngineApi.getConfigurations());
	}

	public searchConfiguration(
		operatorId: string,
		configurationType: ConfigurationTypeNumber,
		configurationMappingType: ConfigurationMappingTypeNumber,
	): Observable<IBillingSchemeConfiguration[]> {
		return this.http.get<IBillingSchemeConfiguration[]>(RulesEngineApi.searchConfigurations(), {
			params: new HttpParams()
				.set('operatorId', operatorId)
				.set('configurationType', configurationType)
				.set('configurationMappingType', configurationMappingType),
		});
	}

	public deleteConfigurations(configId: number): Observable<void> {
		return this.http.delete<void>(RulesEngineApi.deleteConfiguration(configId));
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public createService(body: any): Observable<IServiceInfo> {
		return this.http.post<IServiceInfo>(PurchaseOrderApi.createService, body, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'purchaseOrder' }),
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public createPaymentLinkPurchase(serviceId: string, purchase: any) {
		return this.http.post<IPaymentLinkResponse>(PurchaseOrderApi.createPaymentLinkPurchase(serviceId), purchase);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public createPurchaseOnly(body: any): Observable<IServiceInfo> {
		return this.http.post<IServiceInfo>(PurchaseOrderApi.createPurchaseOnly, body, {
			params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'purchaseOrder' }),
		});
	}

	public createPaymentLinkPayment(purchaseId: string): Observable<IPaymentLinkResponse> {
		return this.http.post<IPaymentLinkResponse>(PurchaseOrderApi.createPaymentLinkPayment(purchaseId), null);
	}

	public getPurchaseSources(collectorId: string, offSystem: boolean): Observable<IPurchaseSource[]> {
		return this.http.get<IPurchaseSource[]>(PurchaseOrderApi.purchaseSource, {
			params: new ParamMetaData({ authToken: AuthType.USER })
				.set('collectorId', collectorId)
				.set('offSystem', offSystem),
		});
	}

	public createOffSystemPayment(
		purchaseDate: Date,
		amount: number,
		comment: string,
		purchaseId: string,
		purchaseSourceId: string,
		priceSelectionOverride: ILineItem[],
	): Observable<void> {
		const offSystemPayment: IOffSystemPayment = {
			purchase_date: purchaseDate,
			amount,
			comment,
			purchase_source_id: purchaseSourceId,
			price_selection_override: priceSelectionOverride,
		};
		return this.http.post<void>(PurchaseOrderApi.createOffSystemPayment(purchaseId), offSystemPayment);
	}

	public createOffSystemPaymentForUnSuspension(
		purchaseDate: Date, amount: number, comment: string, serviceId: string, purchaseSourceId: string, priceSelectionOverride: ILineItem[]): Observable<void> {
		const offSystemPayment: IOffSystemPayment = {
			purchase_date: purchaseDate,
			amount,
			comment,
			purchase_source_id: purchaseSourceId,
			price_selection_override: priceSelectionOverride,
		};
		return this.http.post<void>(PurchaseOrderApi.createOffSystemPaymentForUnSuspension(serviceId), offSystemPayment);
	}

	public createOffSystemPaymentOnDemand(
		purchase_date: Date, amount: number, comment: string, serviceId: string, purchaseSourceId: string, priceSelectionOverride: ILineItem[]): Observable<void> {
		const offSystemPayment: IOffSystemPayment = {
			purchase_date,
			amount,
			comment,
			purchase_source_id: purchaseSourceId,
			price_selection_override: priceSelectionOverride,
		};
		return this.http.post<void>(PurchaseOrderApi.createOffSystemPaymentOnDemand(serviceId), offSystemPayment);
	}

	public getOffSystemPurchaseDetail(paymentId: string): Observable<IPurchaseDetail> {
		return this.http.get<IPurchaseDetail>(PurchaseOrderApi.getOffSystemPurchaseDetail, {
			params: new ParamMetaData({ authToken: AuthType.USER })
				.set('paymentId', paymentId),
		});
	}

	public captureOffset(purchaseId: string, offset: { purchase_date: Date, amount: number, reason: string }): Observable<IOffsetResponse> {
		return this.http.post<IOffsetResponse>(PurchaseOrderApi.captureOffset(purchaseId), offset);
	}

	public captureDayBundleOffset(serviceId: string, offset: { purchase_date: Date, amount: number, reason: string }): Observable<IOffsetResponse> {
		return this.http.post<IOffsetResponse>(PurchaseOrderApi.captureDayBundleOffset(serviceId), offset);
	}
}
