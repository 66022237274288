import { AuthType, IMediumDetailUpdateDto, ParamMetaData } from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediumDetailApi } from '../api';

@Injectable({
	providedIn: 'root',
})
export class MediumDetailService {

	constructor(
		private readonly http: HttpClient,
	) { }

	public updateStripePaymentMediumDetail(
		customerId: string,
		mediumDetailId: string,
		details: string,
		mediumStatusId: number,
	): Observable<IMediumDetailUpdateDto> {
		const mediumDetailUpdateDto: IMediumDetailUpdateDto = {
			customer_id: customerId,
			medium_detail_id: mediumDetailId,
			medium_status_id: mediumStatusId,
			details,
		};
		return this.http.put<IMediumDetailUpdateDto>(
			MediumDetailApi.updateStripePaymentMediumDetail,
			mediumDetailUpdateDto,
			{
				params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'updatePaymentMedium'}),
			},
		);
	}
	public updatePaymentMediumDetail(
		customerId: string,
		mediumDetailId: string,
		details: string,
		mediumStatusId: number,
	): Observable<IMediumDetailUpdateDto> {
		const mediumDetailUpdateDto: IMediumDetailUpdateDto = {
			customer_id: customerId,
			medium_detail_id: mediumDetailId,
			medium_status_id: mediumStatusId,
			details,
		};
		return this.http.put<IMediumDetailUpdateDto>(
			MediumDetailApi.updatePaymentMediumDetail,
			mediumDetailUpdateDto,
			{
				params: new ParamMetaData({ authToken: AuthType.USER, handleError: 'updatePaymentMedium'}),
			},
		);
	}

}
