import { MainRoutingGuard } from '@aex/shared/common-lib';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
				canActivate: [MainRoutingGuard],
			},
		],
	},
	{
		path: '',
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
