import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Subscription, interval } from 'rxjs';

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS = 20;

@Injectable()
export class PwaService {
	subscription: Subscription;

	constructor(private readonly updates: SwUpdate) {
		if (updates.isEnabled)
			interval(MILLISECONDS_IN_SECOND * SECONDS).subscribe(() => {
				updates.checkForUpdate();
			});
	}

	public checkForUpdates(): void {
		this.subscription = this.updates.versionUpdates.subscribe((result: VersionEvent) => {
			if (result.type === 'VERSION_READY')
				this.promptUser();
		});
	}

	private promptUser(): void {
		if (confirm('New version available. Load New Version?')) {
			this.subscription.unsubscribe();

			this.updates.activateUpdate().then(() => {
				document.location.reload();
			});
		}
	}
}
