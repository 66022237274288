import { ENVIRONMENT_CONFIG_TOKEN, IEnvironment } from '@aex/shared/common-lib';
import { ConfigService, GoogleAnalyticsService, PwaService } from '@aex/shared/root-services';
import { Location } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Router} from '@angular/router';
import { capitalize } from 'lodash';
import { ToastContainerDirective } from 'ngx-toastr';
import { zip } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	template: `
		<link rel="stylesheet" [href]='cssUrl'>
		<ngx-ui-loader></ngx-ui-loader>
		<div fxLayout="column" class="vh-100 vw-100">
			<app-header [disableNav]="disableNavbar"></app-header>
			<div class="content-container" toastContainer fxFlex>
				<router-outlet></router-outlet>
				<div *ngIf="footerFloating && !showNewFooter">
					<div *ngIf="standaloneApp">
						<div *ngIf="!isTabletLayout" class="spacer50"></div>
						<div *ngIf="isTabletLayout" class="spacer10"></div>
					</div>
					<app-footer
						*ngIf=" !((router.url === '/coverage') && (configService.operator === 'evotel'))">
					</app-footer>
				</div>
			</div>
			<div *ngIf="showNewFooter">
				<app-footer
					*ngIf=" !((router.url === '/coverage') && (configService.operator === 'evotel'))"></app-footer>
			</div>

			<div *ngIf="!footerFloating && !showNewFooter">
				<app-footer
					*ngIf=" !((router.url === '/coverage') && (configService.operator === 'evotel'))"></app-footer>
			</div>
		</div>
	`,
	styles: [`
		.content-container { overflow: visible; }
		.content-container::-webkit-scrollbar { display: none; }
	`],
})
export class AppComponent {

	@ViewChild(ToastContainerDirective, { static: true }) public toastContainer: ToastContainerDirective;

	public cssUrl: SafeResourceUrl;
	public favIcon: HTMLLinkElement = document.querySelector('#appIcon');
	public manifest: HTMLLinkElement = document.querySelector('#dynamicManifest');
	public themeColor: HTMLMetaElement = document.querySelector('#themeColor');
	public disableNavbar: boolean;

	public standaloneApp = true;
	public footerFloating = true;
	public showNewFooter:boolean = false;
	public showWhatsapp:boolean = false;
	public googleAnalytics = false;
	public googleAnalytics4 = false;
	public isTabletLayout = false;
	private readonly breakpointTablet = 960;

	constructor( // NOSONAR - SQ complains about too many injected services
		public readonly configService: ConfigService,
		public sanitizer: DomSanitizer,
		private readonly titleService: Title,
		public router: Router,
		pwa: PwaService,
		public location: Location,
		private readonly googleAnalyticsService: GoogleAnalyticsService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) private readonly environment: IEnvironment,
	) {
		pwa.checkForUpdates();
		zip(this.configService.configValuesStream, this.configService.operatorStream).pipe(
			filter(([x, y]) => !!x && !!y),
		).subscribe(([configValues, operator]) => {
			const theme = this.environment?.override?.theme ?? operator;
			this.cssUrl = sanitizer.bypassSecurityTrustResourceUrl(`${theme}.css`);
			this.favIcon.href = `assets/img/${theme}-favicon.png`;
			this.manifest.href = `assets/webmanifests/${theme}-manifest.webmanifest`;
			this.titleService.setTitle(`${configValues.prettyName || capitalize(operator)} Client Portal`);
			this.themeColor.content = configValues.themeColor;
			this.disableNavbar = configValues.disableNav;
		});

		if (document.URL?.includes('embedded'))
			this.standaloneApp = false;

		this.footerFloating = configService.configValues.shouldFooterFloat ?? true;

		if (this.footerFloating)
			router.events.subscribe(() => this.footerFloating = !['/landing', '/login'].includes(this.location.path()));


		this.showNewFooter = configService.configValues.showNewLook ?? false;
		this.showWhatsapp = configService.configValues.showNewLook ?? false;
		this.googleAnalytics = configService.configValues.hasGoogleAnalytics ?? false;
		this.googleAnalytics4 = configService.configValues.hasGoogleAnalytics4 ?? false;
		if (this.googleAnalytics || this.googleAnalytics4)
			this.googleAnalyticsService.initialize();
		this.isTabletLayout = window.innerWidth <= this.breakpointTablet;
			window.onresize = () => {
				this.isTabletLayout = window.innerWidth <= this.breakpointTablet;
			};
	}
}
