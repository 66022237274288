import { appCacheRegExp, clearAllDataCaches } from '@aex/ngx-toolbox';
import { AuthType, IAnswers, IInterestQuestion, ParamMetaData, QuestionAnswers } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AssetApi } from '../api';

@Injectable()
export class AssetService {

	constructor(
			private readonly http: HttpClient,
			private readonly configService: ConfigService,
	) { }

	public get questions(): IInterestQuestion[] {
		return this.configService.questionList;
	}

	public get schedule(): boolean {
		return this.configService.configValues.scheduleVisit;
	}

	public getSavedAnswers(locationId: string): Observable<QuestionAnswers> {
		return this.http.get<IAnswers>(AssetApi.interestQuestions(locationId), {
			params: new ParamMetaData({authToken: AuthType.PROXIED, handleError: 'assets'}),
		}).pipe(map(response => response.attributes));
	}

	public saveAssets(locationId: string, attributes: QuestionAnswers, authType: AuthType): Observable<void> {
		return this.http.post<void>(AssetApi.interestQuestions(locationId), {asset: {attributes}}, {
			params: new ParamMetaData({
				authToken: authType,
				handleError: 'premise',
			}),
		}).pipe(
				// Bust the interest question cache
				tap(() => clearAllDataCaches(appCacheRegExp(AssetApi.interestQuestions(locationId, false)))),
		);
	}
}
