import { ILoginResponseDto, LOGIN_RESPONSE_CODE, SECURITY_ROUTES } from '@aex/security/shared';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SecurityLoginHandlerService {
	constructor(
		protected readonly router: Router,
		protected readonly toastrService: ToastrService,
	) { }

	protected handleLogin(returnUrl: string, _loginResponse: ILoginResponseDto = null) {
		this.router.navigateByUrl(returnUrl).then();
	}

	protected handleEmailVerificationNotification() {
		this.toastrService.info('An email has been sent to you to verify your email. Please verify before trying to log in');
	}

	protected handleTwoFactorAuth(
		loginResponse: ILoginResponseDto,
		loginId: string,
	) {
		loginResponse.email = loginId;
		this.router.navigateByUrl(
			SECURITY_ROUTES.twoFactorLogin.name,
			{ state: loginResponse },
		).then();
	}

	protected handleTwoFactorAuthInit(
		loginResponse: ILoginResponseDto,
		loginId: string,
	) {
		loginResponse.email = loginId;
		this.router.navigateByUrl(
			SECURITY_ROUTES.twoFactorInit.name,
			{state: loginResponse},
		).then();
	}

	protected processSuccessfulLoginResponse(
		loginResponse: ILoginResponseDto,
		loginId: string,
		returnUrl: string,
	): boolean {
		switch (loginResponse.successful_status) {
			case LOGIN_RESPONSE_CODE.Successful:
				this.handleLogin(returnUrl, loginResponse);
				return true;
			case LOGIN_RESPONSE_CODE.EmailVerificationRequired:
				this.handleEmailVerificationNotification();
				break;
			case LOGIN_RESPONSE_CODE.TwoFactorAuthInitRequired:
				this.handleTwoFactorAuthInit(loginResponse, loginId);
				break;
			case LOGIN_RESPONSE_CODE.TwoFactorLoginResponseRequired:
				this.handleTwoFactorAuth(loginResponse, loginId);
				break;
			case LOGIN_RESPONSE_CODE.InitiatePasswordChange:
				this.router.navigateByUrl(
						`${SECURITY_ROUTES.changePassword.name}?token=${loginResponse.change_password_id}`,
				).then();
				break;
		}
		return false;
	}

	protected processErrorLoginResponse(loginResponse: ILoginResponseDto): void {
		switch (loginResponse.error_status_code) {
			case LOGIN_RESPONSE_CODE.UserNotFoundOrPasswordWasIncorrect:
				this.toastrService.error('Invalid username or password');
				break;
			case LOGIN_RESPONSE_CODE.UserHasExpired:
				this.toastrService.error('The user has expired');
				break;
			case LOGIN_RESPONSE_CODE.UserIsLocked:
				this.toastrService.error('The user is locked and cannot login');
				break;
			default:
				this.toastrService.error('An error has occurred');
		}
	}

	public determineLoginResponse(
		loginResponse: ILoginResponseDto,
		loginId: string,
		returnUrl: string,
	): boolean {
		if (loginResponse.is_successful) // Successful - but might need to perform additional actions
			return this.processSuccessfulLoginResponse(
				loginResponse,
				loginId,
				returnUrl,
			);
		else // Error
			this.processErrorLoginResponse(loginResponse);

		return false;
	}
}
