<div *ngIf="!isAuthenticationEnabled" class="enable-2fa-page-container">
	<mat-form-field appearance="outline" class="security-form-field method-select">
		<mat-label>Select a Method</mat-label>
		<mat-select [(value)]="selectedMethod">
			<mat-option [value]="'authenticator'">Authenticator App</mat-option>
			<mat-option [value]="'email'">Email</mat-option>
			<mat-option [value]="'sms'">SMS</mat-option>
		</mat-select>
	</mat-form-field>
	<div *ngIf="isAuthenticatorMethod" class="flex flex-row">
		<div class="authenticator-message-container">
			<p>Open your authentication app and add your account by scanning the QR code to the right or by manually entering the secret
				<strong>{{multiFactorSecret.secret_base32_encoded}}</strong>
				Once you have completed the first step, enter the code from your authenticator app in the verification code field below.
			</p>
		</div>
		<div class="qr-code-container">
			<qrcode [qrdata]="qrData" [width]="QR_CODE_WIDTH" [errorCorrectionLevel]="'M'"></qrcode>
		</div>
	</div>
	<div *ngIf="isEmailMethod" class="email-2fa-method-container">
		<mat-form-field appearance="outline" class="security-form-field">
			<mat-label>Email Address</mat-label>
			<input matInput placeholder="Email" [(ngModel)]="email" name="email"  [readonly]="disableEmail" />
		</mat-form-field>
		<div>
			<button mat-raised-button color="primary" class="admin-nexus-button primary" (click)="sendEmailOneTimeCode()">Send One Time Code</button>
		</div>
	</div>
	<div *ngIf="isSmsMethod" class="sms-2fa-method-container">
		<mat-form-field appearance="outline" class="security-form-field mobile-phone-number-input">
			<mat-label>Mobile Phone Number</mat-label>
			<input matInput placeholder="Mobile Phone Number" [(ngModel)]="mobilePhoneNumber" name="mobilePhoneNumber"  [readonly]="disableSms" />
		</mat-form-field>
		<div>
			<button mat-raised-button color="primary" class="admin-nexus-button primary" (click)="sendSmsOneTimeCode()">Send One Time Code</button>
		</div>
	</div>

	<div class="enable-2fa-verification-code">
		<mat-form-field appearance="outline" class="security-form-field verification-code-input">
			<mat-label>Verification Code</mat-label>
			<input matInput placeholder="Code" [(ngModel)]="verificationCode" name="verificationCode" />
		</mat-form-field>
		<div>
			<button mat-raised-button color="primary" class="admin-nexus-button primary" (click)="enableTwoFactor()">Enable Two Factor</button>
		</div>
	</div>
</div>

<div *ngIf="isAuthenticationEnabled" class="two-factor-enabled-container">
	<span>Successfully saved {{selectedMethod}} </span>
	<div *ngIf="isAuthenticatorMethod" class="recovery-codes-container">
		<span>Please keep these recovery codes in a safe place.</span>
		<div class="recovery-code-block">
			<ng-container *ngFor="let recoveryCode of recoveryCodes">
				<span class="recovery-code" >{{recoveryCode}}</span>
			</ng-container>
		</div>
	</div>
	<div>
		<button mat-raised-button class="admin-nexus-button primary" (click)="closeSuccess()">I have saved the recovery codes.</button>
	</div>
</div>
