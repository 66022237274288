import { Injectable } from '@angular/core';
import { ICustomer } from '../customer';
import { KEY_AUTH_TOKEN, KEY_PROVIDER_TOKEN, KEY_CURRENT_USER } from '../consts';
import { AES, enc, lib } from 'crypto-js';
import { AuthService } from '@aex/shared/root-services';
import { CustomerService } from '@aex/shared/apis';

@Injectable()
export class ExternalRoutingService {

    constructor(private readonly authService: AuthService, private readonly customerService: CustomerService) { }

    private generateKey(): string {
        return lib.WordArray.random(4).toString();
    }

    private encrypt(value: string, secretKey: string): string {
        return AES.encrypt(value, secretKey).toString();
    }

    private decrypt(value: string, secretKey: string): string {
        const bytes = AES.decrypt(value, secretKey);

        if (bytes.toString())
            return bytes.toString(enc.Utf8);

        return value;
    }

    public saveAuth(): string {
        const key = this.generateKey();
        const eAuthToken = this.encrypt(this.authService.authToken, key);
        const eCurrentUser = this.encrypt(JSON.stringify(this.customerService.currentUser), key);

        localStorage.setItem(KEY_AUTH_TOKEN, eAuthToken);
        localStorage.setItem(KEY_CURRENT_USER, eCurrentUser);

        return key;
    }

    public applyAuth(key: string): void {
        const getAndRemoveLocalStorageItem = (key: string): string => {
            const value = localStorage.getItem(key);
            localStorage.removeItem(key);
            return value;
        };

        const eAuthToken = getAndRemoveLocalStorageItem(KEY_AUTH_TOKEN);
        const eProviderToken = getAndRemoveLocalStorageItem(KEY_PROVIDER_TOKEN);
        const eCurrentUser = getAndRemoveLocalStorageItem(KEY_CURRENT_USER);

        if (!eAuthToken || !eProviderToken || !eCurrentUser)
            return null;

        const authToken = this.decrypt(eAuthToken, key);
        const currentUserString = this.decrypt(eCurrentUser, key);
        const currentUser: ICustomer = JSON.parse(currentUserString);

        this.authService.setAuthToken(authToken);
        this.customerService.currentUser = currentUser;
    }

    public redirect(url: string): void {
        window.location.href = url;
    }
}
