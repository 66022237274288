import {
	AuthType,
	ICreditCardDetail,
	ICreditPurchase, ILineItem,
	IPaymentHistory,
	IProduct,
	ParamMetaData,
	ProductPriceType,
	PurchaseStatusCalculate,
} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentApi } from '../api';
import { IPayFastMediumCardUpdate, IPaymentResponse, IRefundResponse} from 'libs/shared/common-lib/src/interfaces/payment';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root',
})
export class PaymentService {

	constructor(private readonly http: HttpClient) { }
	public validateCreditCard(creditCardDetails: ICreditCardDetail) {
		return this.http.post(PaymentApi.creditCardDetails, creditCardDetails, {
			params: new ParamMetaData({ authToken: AuthType.USER }),
		});
	}

	public getPaymentDetails(serviceId: string, product: IProduct): ICreditPurchase {
		let purchase;
		if (product && product.pricing) {
			const recurringPrice = product.pricing.find(
				item => item.price_type.toLowerCase() === 'monthly',
			);
			purchase = {
				service_id: serviceId,
				product_id: product.id,
				product_type: recurringPrice ? ProductPriceType.MONTHLY : ProductPriceType.DAY_TO_DAY,
			};
		}
		return purchase;
	}

	public captureRefund(paymentId: string, refund: { date: Date, amount: number, reason: string, isOffSystem: boolean, isFullRefund: boolean }): Observable<IRefundResponse> {
		return this.http.put<IRefundResponse>(PaymentApi.captureRefund(paymentId), refund);
	}

	public addRefundsToHistory(paymentHistory: IPaymentHistory[]): IPaymentHistory[]
	{  let refunds : IPaymentHistory[] = [];
		// need to go through payment history list and create dummy refund purchases to show on the screen
		paymentHistory.forEach((purchase) => {
			purchase.payments.forEach((payment) => {
				if (payment.refunds !== null)
					payment.refunds.forEach((refund) => {
						// this where we create the dummy purchase
						const refundPurchase: IPaymentHistory = {
							id: refund.id,
							status: PurchaseStatusCalculate.Refunded,
							purchase_status_as_text: "Refunded",
							amount: (-refund.amount).toString(),
							tax_amount: '0',
							amount_excl: (-refund.amount).toString(),
							purchase_date: refund.refund_date,
							payment_date: refund.refund_date,
							medium_type: payment.medium_type_code,
							tppp_reference: refund.external_reference,
							medium_status: payment.medium_detail_description,
							collection_day: payment.collection_day,
							result_message: "Refund Completed",
							service_id: purchase.service_id,
							is_purchase_payment_retry_allowed: false,
							is_off_system_payment_allowed: false,
							is_refund: true,
							refund_payment_id: payment.payment_id,
							payments: [],
							lines: [],
							splits: [],
						}
						const refundLine : ILineItem = {
							description: refund.reason,
							total_price: -refund.amount,
							total_price_inc_tax: -refund.amount,
							price_per_quantity: -refund.amount,
							price_per_quantity_inc_tax: -refund.amount,
							tax_rate: 0,
							net_amount_excl: 0,
							tax_amount: 0,
							quantity: 1,
							once_off: true,
							amount_per_quantity: -refund.amount,
							total_amount:-refund.amount,
						}
						refundPurchase.lines.push(refundLine);
							const refundPayment: IPaymentResponse =
							{
								payment_id: payment.payment_id,
								adjustment_audit_ids: payment.adjustment_audit_ids,
								original_amount: -refund.amount,
								adjusted_amount: -refund.amount,
								payment_external_reference: "Refund",
								medium_detail_id: payment.medium_detail_id,
								medium_detail_description: payment.medium_detail_description,
								plan_id: payment.plan_id,
								action_date: refund.refund_date,
								payment_status: payment.payment_status,
								customerStatementRef: "Refund",
								sequence: payment.sequence,
								tppp_reference:refund.external_reference,
								medium_type_id: payment.medium_type_id,
								medium_type_code: payment.medium_type_code,
								result_message: "Refund Completed",
								medium_status: payment.medium_status,
								collection_day: payment.collection_day,
								payment_date: refund.refund_date,
								refund_amount_allowed: 0,
								reason: refund.reason,
								refunds: [],
							};
						refundPurchase.payments.push(refundPayment);
						// Adding the new purchase to the paymentHistory array
						refunds.push(refundPurchase);
					});
			});
		});
		return paymentHistory.concat(refunds)
			.sort((a: IPaymentHistory, b: IPaymentHistory) => {
				let dateA = new Date(a.purchase_date).getTime();
				let dateB = new Date(b.purchase_date).getTime();
				return dateA < dateB ? 1 : -1;
			});
	}

	public GetPayFastCardMediumUpdateUrl(mediumDetailId: string): Observable<IPayFastMediumCardUpdate> {
		return this.http.get<IPayFastMediumCardUpdate>(PaymentApi.getPayFastCardMediumUpdateUrl(mediumDetailId), {
			params: new ParamMetaData({ authToken: AuthType.USER }),
		});
	}
}
