import { Component } from '@angular/core';
import {
	ActivatedRoute,
	Router,
} from "@angular/router";
import {
	ILoginResponseDto, 
  ISendTwoFactorLoginMessageRequestDto, 
  ISendTwoFactorMessageResponseDto, 
  ITwoFactorLoginRequestDto,
	ITwoFactorMethodDto, 
  KnoxAuthApiService, 
  SecurityAuthService, 
  TWO_FACTOR_LOGIN_RESPONSE_CODE, TWO_FACTOR_METHODS,
} from "@aex/security/shared";
import {AuthService, BaseConfigService} from "@aex/shared/root-services";
import {ToastrService} from "ngx-toastr";
import {KEY_LOGIN_ID} from "@aex/shared/common-lib";

export class SelectedMethodDto {
	public methodId: string;
	public methodName: string;
	public method: string;
	public description: string;
	public order: number;
}

@Component({
	selector: 'app-two-factor-login',
	templateUrl: './two-factor-login.component.html',
	styleUrls: ['./two-factor-login.component.scss'],
})
export class TwoFactorLoginComponent {

	public loginId: string;
	public verificationCode: string;
	public trustComputer: boolean;
	private readonly loginResponseDto: ILoginResponseDto;
	public twoFactorMethods: ITwoFactorMethodDto[];
	private get securityAuthService(): SecurityAuthService{
		return this.authService as SecurityAuthService;
	}
	public selectableMethods: SelectedMethodDto[] = [];
	public showSelection = false;
	public showVerificationCodeCapture = false;

	public selectedMethod: SelectedMethodDto;
	constructor(
			private readonly route: ActivatedRoute,
			private readonly router: Router,
			private readonly toastrService: ToastrService,
			private readonly knoxAuthApiService: KnoxAuthApiService,
			private readonly authService: AuthService,
			private readonly configService: BaseConfigService,
	) {
		this.loginId = this.configService.retrieve(KEY_LOGIN_ID);
		this.loginResponseDto = this.router.getCurrentNavigation().extras.state as ILoginResponseDto;
		this.route.data.subscribe( ({ userTwoFactorMethods }) => {
			this.twoFactorMethods = userTwoFactorMethods;
			this.initialiseTwoFactorMethod(this.twoFactorMethods);
		});
	}

	private sendVerificationCode(methodId: string, method: string): void	{
		const requestDto: ISendTwoFactorLoginMessageRequestDto = {
			login_id: this.loginResponseDto.email,
			method:  method,
			method_id: methodId,
		};
		// Send email
		this.knoxAuthApiService.sendTwoFactorLoginMessage(
				this.loginResponseDto.two_factor_id,
				requestDto,
		).subscribe(
				(response: ISendTwoFactorMessageResponseDto)=> {
					this.loginResponseDto.two_factor_id = response.two_factor_id;
					this.toastrService.info("Verification Code sent");
				},
		);
	}

	private initialiseTwoFactorMethod(twoFactorMethods: ITwoFactorMethodDto[]): void {

		// Let user select method
		const tempSelectableMethod: SelectedMethodDto[] = [];
		let selectedMethod: SelectedMethodDto;
		for (const twoFactorMethod of this.twoFactorMethods)
			switch (twoFactorMethod.method) {
				case TWO_FACTOR_METHODS.Authenticator: {
					selectedMethod = {
						methodId: twoFactorMethod.id,
						method: twoFactorMethod.method,
						methodName: 'Authenticator',
						description: 'Get a code from your authenticator app',
						order: 1,
					};
					tempSelectableMethod.push(selectedMethod);

					selectedMethod = {
						methodId: twoFactorMethod.id,
						method: 'Recovery Code',
						methodName: 'Recovery Code',
						description: 'Use one of your 10 recovery codes',
						order: 100,
					};
					tempSelectableMethod.push(selectedMethod);
					break;
				}
				case TWO_FACTOR_METHODS.Email: {
					selectedMethod = {
						methodId: twoFactorMethod.id,
						method: twoFactorMethod.method,
						methodName: 'Email message',
						description: `Get a code at ${twoFactorMethod.email?.substring(0, 10)}...`,
						order: 1,
					};
					tempSelectableMethod.push(selectedMethod);
					break;
				}
				case TWO_FACTOR_METHODS.SMS: {
					let mobileNumber = twoFactorMethod.mobile_phone;
					selectedMethod = {
						methodId: twoFactorMethod.id,
						method: twoFactorMethod.method,
						methodName: 'SMS message',
						description: `Get a code at ${mobileNumber?.substring(0, 4)}.. ${mobileNumber?.substring(mobileNumber?.length - 4, mobileNumber?.length - 1)}.`,
						order: 1,
					};
					tempSelectableMethod.push(selectedMethod);
					break;
				}
			}

		this.selectableMethods = tempSelectableMethod.sort((item1, item2)=>  item1.order - item2.order);

		if (twoFactorMethods.length === 1)
		{
			// Determine which code to use? If email send verification code to email address
			const twoFactorMethod = twoFactorMethods[0];

			if (twoFactorMethod.method !== TWO_FACTOR_METHODS.Authenticator)
				this.sendVerificationCode(twoFactorMethod.id,twoFactorMethod.method);

			this.selectedMethod = {
				methodId: twoFactorMethod.id,
				method: twoFactorMethod.method,
				methodName: twoFactorMethod.method,
				description: twoFactorMethod.method,
				order: 100,
			};
			this.showVerificationCodeCapture = true;
		}
		else if (twoFactorMethods.length > 1 )
			this.showSelection = true;
		else
				// should not get here
			this.showVerificationCodeCapture = true;

	}

	private handleTwoFactorLoginError(loginResponseDto: ILoginResponseDto): void{
		switch (loginResponseDto.error_status_code)
		{
			case TWO_FACTOR_LOGIN_RESPONSE_CODE.TwoFactorIdHasExpired:
				this.toastrService.error("Your login session has expired. Please login again.");
				this.router.navigateByUrl('login');
				break;
			case TWO_FACTOR_LOGIN_RESPONSE_CODE.UserInActionPreventingLogin:
				this.toastrService.error("User is currently in an action that is preventing login");
				break;
			case TWO_FACTOR_LOGIN_RESPONSE_CODE.UserHasExpired:
				this.toastrService.error("User has expired.");
				break;
			case TWO_FACTOR_LOGIN_RESPONSE_CODE.InvalidCode:
				this.toastrService.error("Invalid verification code");
				break;
			default:
				this.toastrService.error("An error has occurred:");
		}
	}

	public verifyCode(): void{
		const request : ITwoFactorLoginRequestDto = {
			login_id: this.loginResponseDto.email,
			two_factor_id:  this.loginResponseDto.two_factor_id,
			code: this.verificationCode,
			trust_computer: this.trustComputer,
		}
		this.knoxAuthApiService.twoFactorLogin(
				request,
		).subscribe((response: ILoginResponseDto) =>{
			if (response.is_successful)
			{
				this.securityAuthService.processLoginResponse(this.loginId, response);
				this.router.navigateByUrl('/home').then();
			}
			else
				this.handleTwoFactorLoginError(response);
		})
	}

	public selectMethod(selectedMethodDto: SelectedMethodDto): void{
		this.selectedMethod = selectedMethodDto;
		this.showSelection = false;
		if (this.selectedMethod.method === TWO_FACTOR_METHODS.Email || this.selectedMethod.method === TWO_FACTOR_METHODS.SMS)
			this.sendVerificationCode(this.selectedMethod.methodId, this.selectedMethod.method);

		this.showVerificationCodeCapture = true;
	}

	public goBack() :void{
		this.selectedMethod = null;
		this.showSelection = true;
		this.showVerificationCodeCapture = false;
	}
}
