<div class="two-factor-page-container">
	<h3 class="admin-nexus-heading">
		Authentication Challenge
	</h3>

	<hr />

	<div *ngIf="showSelection" class="two-factor-selection-container">

		<div class="summary">
			<span>A second step is required to complete sign in. Select one of the following methods to complete login.</span>
		</div>

		<mat-card *ngFor="let selectableMethod of this.selectableMethods"  class="two-factor-selectable-method"
			(click)="selectMethod(selectableMethod)" matRipple>
			<div class="two-factor-selectable-method-inner-container">
				<div class="selectable-method-details">
					<h3 class="selected-method-name">{{selectableMethod.methodName}}</h3>
					<span class="selected-method-description">{{selectableMethod.description}}</span>
				</div>
				<div class="selectable-method-action">
					<button mat-icon-button  color="accent"><fa-icon [icon]="'arrow-right'" class="select-icon"></fa-icon></button>
				</div>
			</div>
		</mat-card>
	</div>

	<mat-card *ngIf="showVerificationCodeCapture">
		<div class="two-factor-verification-code">
			<mat-form-field appearance="outline" class="security-form-field verification-code-input">
				<mat-label>Verification Code</mat-label>
				<input matInput placeholder="Name" [(ngModel)]="verificationCode" name="verificationCode" />
			</mat-form-field>
			<div>
				<button mat-raised-button color="primary" class="admin-nexus-button primary" (click)="verifyCode()">Verify Code</button>
			</div>
		</div>
		<div (click)="goBack()">
			<button mat-icon-button  color="accent"><fa-icon [icon]="'arrow-left'" ></fa-icon></button>
			<span>Didn't receive a code? Try another option</span>
		</div>
	</mat-card>
</div>