import { IPagedResponse } from '@aex/ngx-toolbox';
import { WorkOrderApi } from '@aex/shared/apis';
import {
	IPagination,
	IWorkOrder,
	IWorkOrderHistory,
	IWorkOrderStatusResponse,
	IWorkOrderUpdate,
	IWorkOrders,
	ParamMetaData,
	WorkOrderType,
	IGridColumn, 
	AuthType,
} from '@aex/shared/common-lib';
import { AuthService } from '@aex/shared/root-services';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITasksGridData } from 'libs/shared/common-lib/src/interfaces/task';

@Injectable({
	providedIn: 'root',
})
export class WorkOrderService {
	public customerId: string;

	constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

	public getWorkOrder(workorderId: string): Observable<IWorkOrder> {
		return this.http.get<IWorkOrder>(WorkOrderApi.workOrder(workorderId));
	}

	public getServiceWorkOrder(serviceId: string, type: number = null): Observable<IPagedResponse<IWorkOrder>> {
		const params = new ParamMetaData({ handleError: 'workorders', authToken: AuthType.PROXIED }).set('service', serviceId).withAllCount();
		if (type) params.set('type', type);
		return this.http
			.get<IPagedResponse<IWorkOrder>>(WorkOrderApi.workOrders, {
				params,
			})
			.pipe(
				map((workOrders) => {
					workOrders?.items?.filter((workOrder) => isEmpty(workOrder.close_date) && workOrder.status_id !== WorkOrderType.Cancellation.toString());
					return workOrders;
				}),
			);
	}

	public getWorkOrders(): Observable<IWorkOrder[]> {
		return this.http.get<IWorkOrder[]>(WorkOrderApi.workOrders);
	}

	public getQuotePDF(workOrderRef: string): Observable<Blob> {
		return this.http.get(WorkOrderApi.quoteLocation(workOrderRef), {
			responseType: 'blob',
		});
	}

	public updateWorkOrder(workOrderId: string, workOrder: Partial<IWorkOrder>): Observable<IWorkOrder> {
		return this.http.put<IWorkOrder>(
			WorkOrderApi.workOrder(workOrderId),
			{ work_order: workOrder },
			{
				params: new ParamMetaData({ handleError: 'workorders' }),
				headers: { Authorization: this.authService.authToken },
			},
		);
	}

	public createWorkOrder(workOrder: IWorkOrderUpdate): Observable<IWorkOrderUpdate> {
		return this.http.post<IWorkOrderUpdate>(WorkOrderApi.createWorkOrder(), workOrder, {
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getWorkOrderStatuses(workOrderTypeId: number, count: number = 10): Observable<IWorkOrderStatusResponse> {
		return this.http.get<IWorkOrderStatusResponse>(WorkOrderApi.workOrderStatuses(workOrderTypeId, count), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getWorkOrderHistory(workOrderId: string): Observable<IWorkOrderHistory[]> {
		return this.http.get<IWorkOrderHistory[]>(WorkOrderApi.workOrderHistory(workOrderId));
	}

	public getAllTasks(pagination: IPagination): Observable<HttpResponse<ITasksGridData>> {
		return this.http.post<ITasksGridData>(WorkOrderApi.workOrdersSearch, pagination, { observe: 'response' });
	}

	public getAllTaskColumns(): Observable<HttpResponse<IGridColumn[]>> {
		return this.http.get<IGridColumn[]>(WorkOrderApi.columns(), { observe: 'response' });
	}

	public exportTaskGridData(selected_grid_columns: IGridColumn[]): Observable<string> {
		return this.http.post(WorkOrderApi.exportCsv(), { selected_grid_columns: selected_grid_columns }, { responseType: 'text' });
	}

	public getAllWorkOrders(payload?: unknown): Observable<HttpResponse<IWorkOrders>> {
		let body = {};
		if (payload) body = payload;
		return this.http.post<IWorkOrders>(WorkOrderApi.statusCounter(), body, { observe: 'response' });
	}
}
