import { ITriageConfig, ITriageInstance, ITriageSnapshot, ParamMetaData } from '@aex/shared/common-lib';
import { AuthService } from '@aex/shared/root-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupportApi } from '../api';

@Injectable({
	providedIn: 'root',
})
export class SupportService {

	constructor(
		private readonly http: HttpClient,
		private readonly authService: AuthService,
	) { }

	public getTriageConfig(): Observable<ITriageConfig> {
		return this.http.get<ITriageConfig>(SupportApi.triageConfig, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public updateTriageConfig(config: Partial<ITriageConfig>): Observable<ITriageConfig> {
		return this.http.post<ITriageConfig>(SupportApi.triageConfig, config, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public getTriageInstance(): Observable<ITriageInstance> {
		return this.http.get<ITriageInstance>(SupportApi.triageInstances, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public createTriageInstance(instance: Partial<ITriageInstance>): Observable<ITriageInstance> {
		return this.http.post<ITriageInstance>(SupportApi.triageInstances, instance, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public updateTriageInstance(instanceId: string, instance: Partial<ITriageInstance>): Observable<ITriageInstance> {
		return this.http.put<ITriageInstance>(SupportApi.triageInstance(instanceId), instance, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public getTriageInstancePerPerson(personId: string): Observable<ITriageInstance[]> {
		return this.http.get<ITriageInstance[]>(SupportApi.triageInstancesPerPerson(), {
			params: new ParamMetaData({ authToken: this.authService.authToken })
				.set('userId', personId),
		});
	}

	public getTriageInstancePerId(externalId: string): Observable<ITriageInstance> {
		return this.http.get<ITriageInstance>(SupportApi.triageInstancesPerId(externalId), {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public getTriageSnapshot(): Observable<ITriageSnapshot> {
		return this.http.get<ITriageSnapshot>(SupportApi.triageSnapshot, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

	public createTriageSnapshot(snapshot: Partial<ITriageSnapshot>): Observable<ITriageSnapshot> {
		return this.http.post<ITriageSnapshot>(SupportApi.triageSnapshot, snapshot, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
		});
	}

	public updateTriageSnapshot(snapshot: Partial<ITriageSnapshot>): Observable<ITriageSnapshot> {
		return this.http.put<ITriageSnapshot>(SupportApi.triageSnapshot, snapshot, {
			params: new ParamMetaData({ authToken: this.authService.authToken }),
		});
	}

}
