import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProvidersApi } from '../api';
import { AuthType, IMediumTypeProvider, ParamMetaData } from '@aex/shared/common-lib';


@Injectable({
	providedIn: 'root',
})

export class ProvidersService {

	constructor(private readonly http: HttpClient) {
	}

	public getDefaultProviderByMediumType(mediumTypeId: number): Observable<IMediumTypeProvider> {
		return this.http.get<IMediumTypeProvider>(ProvidersApi.getDefaultProviderByMediumType(), {
			params: new ParamMetaData({ authToken: AuthType.NONE })
				.set('mediumType', mediumTypeId),
		});
	}
}
