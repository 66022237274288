import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {InvoiceApi } from '../api';
import {AuthType, IRefundDocRequest, ParamMetaData} from "@aex/shared/common-lib";

@Injectable({
	providedIn: 'root',
})
export class InvoiceService {

	constructor(private readonly http: HttpClient) { }
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getInvoice(operatorId: string, serviceId: string, id: string): Observable<any> {
		return this.http.post(InvoiceApi.DownloadInvoice(operatorId, serviceId, id), { responseType: 'blob' });
	}


	public getRefundDocument(
		operatorId: string,
		serviceId: string,
		paymentId: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		refundId: string ): Observable<any> {
		const refundDocRequest: IRefundDocRequest = {
			operator_id: operatorId,
			service_id: serviceId,
			payment_id: paymentId,
			refund_id: refundId,
		};
		return this.http.post(
			InvoiceApi.DownloadRefundDocument(),
			refundDocRequest,
			{
				params: new ParamMetaData({ authToken: AuthType.USER }),
			},
		);
	}

	public base64toBlob(base64Data: string): Blob {
		const sliceSize = 1024;
		const byteCharacters = atob(base64Data);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset)
				bytes[i] = byteCharacters[offset].charCodeAt(0);

			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, {type: 'application/pdf'});
	}

}
