import {
	DialogService,
	isRouteWhereImAt,
	WithDestroy,
} from '@aex/ngx-toolbox';
import { APP_ROUTES, IGlobalConfig, IHeaderRoutes } from '@aex/shared/common-lib';
import { AuthService, ConfigService, PromptInstallService } from '@aex/shared/root-services';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DashboardViewService } from '@shared/services/dashboard-view.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends WithDestroy() implements OnInit {
	@Input() public disableNav: boolean;

	public readonly showFnoName = this.configService.operatorPrettyName;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public installPrompt: any;
	public confirm = false;
	public headerRoutes: Partial<IHeaderRoutes>[] = [];
	public downloadAppRoute: Partial<IHeaderRoutes> = { name: 'Download', path: '/download' };

	private static hideNav(): void {
		const element = document.getElementById('navbarCollapse');
		if (element)
			element.className = 'navbar-collapse collapse';
	}

	public get showCoverageLink(): boolean {
		if (this.configValues?.hidePackagesForCRM ?? false)
			return false;
		else
			return !isRouteWhereImAt(APP_ROUTES.shop) || this.configValues.staticDisplay;
	}

	public get showDashboardLink(): boolean {
		return (this.authService.isLoggedIn && !isRouteWhereImAt(APP_ROUTES.dashboard)) || this.configValues.staticDisplay;
	}

	public get configValues(): IGlobalConfig { return this.configService.configValues; }

	public get showNewLook(): boolean { return !!this.configValues.showNewLook; }

	public get showLoginLink(): boolean {
		return !this.authService.isLoggedIn && (!isRouteWhereImAt(APP_ROUTES.login) || this.configValues.staticDisplay);
	}

	public get showRegisterLink(): boolean {
		return (!this.authService.isLoggedIn && !isRouteWhereImAt(APP_ROUTES.login)) && !this.configValues.hideRegister;
	}

	public get showLogoutLink(): boolean { return this.authService.isLoggedIn; }

	public get showExternalContact(): boolean { return !!this.configValues?.externalContactLink; }
	public get showExternalHome(): boolean { return !!this.configValues?.externalHomeLink; }
	public get showExternalShop(): boolean { return !!this.configValues?.externalShopLink; }

	constructor(
		private readonly authService: AuthService,
		public configService: ConfigService,
		private readonly router: Router,
		private readonly dialogs: DialogService,
		private readonly pwa: PromptInstallService,
		private readonly dashboardViewService: DashboardViewService,
	) {
		super();
	}

	public ngOnInit(): void {
		this.router.events.forEach((event) => {
			if (!(event instanceof NavigationEnd)) return;

			this.setHeaderRoutes();
		});

		this.noZombie(this.authService.authOverride).subscribe(_ => {
			this.setHeaderRoutes();
		});

		this.noZombie(this.authService.loggedInStream).subscribe(() => {
			this.setHeaderRoutes();
		});

		this.noZombie(this.pwa.installEventStream).subscribe(x => this.installPrompt = x);

		this.configService.getConfirmChanges.subscribe((res) => this.confirm = res);
	}

	private setHeaderRoutes(): void {
		const reRoutes = [
			{
				name: this.configValues?.homeLabel || 'Home',
				path: this.configValues?.externalHomeLink || '/dashboard',
				show: !this.configValues?.dontShowHomeLink ?? this.showDashboardLink,
				isExternal: this.showExternalHome,
				size: 'short',
				button: false,
				link: true,
			},
			{
				name: this.configValues?.packagesLabel || 'Coverage & Products',
				path: this.configValues?.externalShopLink || '/shop',
				show: this.showCoverageLink,
				isExternal: this.showExternalShop,
				size: 'long',
				button: false,
				link: true,
			},
			{
				name: 'Contact',
				path: this.configValues?.externalContactLink,
				show: this.showExternalContact,
				isExternal: this.showExternalContact,
				size: 'short',
				button: false,
				link: true,
			},
			{
				name: 'Login',
				path: '/login',
				show: this.showLoginLink,
				size: 'short',
				button: false,
				link: true,
			},
			{
				name: 'Register',
				path: '/register',
				show: this.showRegisterLink,
				size: 'short',
				button: false,
				link: true,
			},
			{
				name: 'Logout',
				path: '/logout',
				show: this.showLogoutLink,
				size: 'short',
				button: false,
				link: true,
			},
			{
				show: !!this.configValues.showNewLook,
				name: 'Download App',
				path: '/download',
				button: true,
				link: false,
			},
		];
		this.headerRoutes = [...reRoutes.filter(x => x.show)];
	}

	public support(): void {
		this.router.navigateByUrl(APP_ROUTES.support.path).then();
	}

	private logout(): void {
		// reset the dashboard currentView
		// this.dashboardViewService.currentView = '';
		this.authService.logout().subscribe();
		this.router.navigateByUrl(APP_ROUTES.login.path).then();
	}

	public navigateConfirm(route: Partial<IHeaderRoutes>): void {
		if (this.confirm && route.isExternal) {
			this.confirm = false;
			this.dialogs.confirmLoseChanges({autoSave: true}, !!this.configValues?.showRoundedButtons).subscribe(() => this.navigate(route));
		} else
			this.navigate(route);
	}

	public navigate(route: Partial<IHeaderRoutes>): void {
		if (!route) return;

		HeaderComponent.hideNav();
		if (route.path.includes('logout'))
			this.logout();
		else if (route.path.includes('download'))
			this.installPrompt.prompt();
		else if (route.isExternal)
			parent.window.location.href = route.path;
		else
			this.router.navigateByUrl(route.path).then();
	}

	public clickHomeLogo(): void {
		if (this.configValues.operatorWebsite && this.configValues.useDefaultLanding)
			parent.window.location.href = this.configValues.operatorWebsite;
		else
			this.handleHomeLogoNav();
	}

	private handleHomeLogoNav() {
		if(!this.authService.isLoggedIn)
			this.router.navigateByUrl(APP_ROUTES.landing.path).then();
		else {
			if (this.configValues.useDashboardDefaultView)
				this.dashboardViewService.currentView = this.configValues.useDashboardDefaultView;

			this.router.navigateByUrl(APP_ROUTES.dashboard.path).then();
		}
	}

}
