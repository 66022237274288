import { AexNgToolboxModule } from "@aex/ngx-toolbox";
import { SecurityCommonModule } from "@aex/security/common";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { IdentityProviderCallbackComponent } from './components/identity-provider-callback/identity-provider-callback.component';
import { LoginComponent } from './components/login/login.component';
import { TwoFactorInitComponent } from './components/two-factor-init/two-factor-init.component';
import { TwoFactorLoginComponent } from './components/two-factor-login/two-factor-login.component';
import { GetUserTwoFactorMethodsResolver } from "./resolvers/two-factor-login.resolver";
import { SecurityAuthenticationRoutingModule } from './security-authentication-routing.module';
import { SecurityLoginHandlerService } from './services/security-login-handler.service';

@NgModule({
	declarations: [
		LoginComponent,
		ForgotPasswordComponent,
		IdentityProviderCallbackComponent,
    ChangePasswordComponent,
    TwoFactorLoginComponent,
    TwoFactorInitComponent,
	],
	exports: [],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		FormsModule,
		AexNgToolboxModule.forChild(),
		MatInputModule,
		MatIconModule,
		MatSnackBarModule,
		MatButtonModule,
		SecurityAuthenticationRoutingModule,
		FontAwesomeModule,
		MatCardModule,
		MatCheckboxModule,
		MatDividerModule,
		MatRippleModule,
		SecurityCommonModule,
	],
	providers: [
		GetUserTwoFactorMethodsResolver,
		SecurityLoginHandlerService,
	],
})
export class SecurityAuthenticationModule {}
